import React, { Component } from 'react';
import { refreshView, SelectInput } from 'react-admin';
import { Divider, Container, Form, Segment, Button, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import Chart from 'chart.js';
import { SCRAPPER_API, FRONTEND_API_TOKEN } from '../../config';
import 'moment/locale/fr';
import { sitesChoices, datatypesChoices, proxyChoices } from '../selectInputs';

moment.locale('fr');

class TracesChart extends Component {
	constructor(props) {
		super(props);
    this.state = {};
	}

	_instantiateChart(res) {
		var ctx = document.getElementById('myChart').getContext('2d');

		this.state.myChart = new Chart(ctx, {
			type: 'bar',
			data: {
    		labels: res.label,
        datasets: res.datasets
	    },
	    options: {
         scales: {
            xAxes: [{
                stacked: true
            }],
            yAxes: [{
                stacked: true
            }]
        }
	    }
		});
	}

	_updateChart(res) {
		if (!this.state.myChart) this._instantiateChart(res);

  	this.state.myChart.data.labels = res.label;
  	this.state.myChart.data.datasets = res.datasets;

  	this.state.myChart.update();
	}

	queryUpdate() {
		var url = `${SCRAPPER_API}/traces/stats?`;

		var site =  this.state.site;
    var dataType = this.state.dataType;
    var isbn = this.state.isbn;
    var timeScale = this.state.timeScale;
    var tenDaysDisplay = this.state.tenDaysDisplay;
    var proxy = this.state.proxy;

		if (site) url += 'site=' + site + '&';
    if (dataType) url += 'dataType=' + dataType + '&';
    if (isbn) url += 'isbn=' + isbn + '&';
    if (timeScale) url += 'timeScale=' + timeScale + '&';
    if (tenDaysDisplay) url += 'tenDaysDisplay=' + tenDaysDisplay + '&';
    if (proxy) url += 'proxy=' + proxy + '&';

    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${FRONTEND_API_TOKEN}`
      }
    })
      .then(res => {
        console.log({ res });
        return res.json();
      })
      .then(res => {
      	this._updateChart(res);
      })
	}

  createSelectOptions() {
    return sitesChoices.map(function(value, index) {
      return "<option value='" + value.id + "'>" + value.name + '</option>';
    })
  }

	componentDidMount() {
		var url = `${SCRAPPER_API}/traces/stats?`;

    /*fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${FRONTEND_API_TOKEN}`
      }
    })
      .then(res => {
        console.log({ res });
        return res.json();
      })
      .then(res => {
      	this._instantiateChart(res);
      })*/
  }

	render() {
		return (
			<Container>
      <Segment inverted>
         <Form
            inverted
            onSubmit={this.queryUpdate.bind(this)}
            autoComplete="off"
            error={this.state.error && this.state.error.length > 0}
          >
            <Form.Group widths="equal">
              <Form.Field
                control="input"
                label="Isbn"
                placeholder="Isbn"
                onChange={(e) => {
                  this.setState({ isbn: e.target.value });
                }}
              />
              <Form.Field
                control="select"
                label="Unité de temps"
                placeholder="TimeScale"
                onChange={(e) => {
                  this.setState({ timeScale: e.target.value });
                }}
              >
                <option value="hour" default>Heure</option>
                <option value="day">Jour</option>
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control="select"
                label="Site"
                placeholder="Site"
                onChange={(e) => {
                  this.setState({ site: e.target.value });
                }}
              >
                <option></option>
                {sitesChoices.map((value, index) => {
                  return <option value={value.id}>{value.name}</option>
                }
                )}
              </Form.Field>
              <Form.Field
                control="select"
                label="DataType"
                placeholder="DataType"
                onChange={(e) => {
                  this.setState({ dataType: e.target.value });
                }}
              >
                <option></option>
                {datatypesChoices.map((value, index) => {
                  return <option value={value.id}>{value.name}</option>
                }
                )}
              </Form.Field>
              <Form.Field
                control="select"
                label="Proxy"
                placeholder="Proxy"
                onChange={(e) => {
                  this.setState({ proxy: e.target.value });
                }}
              >
                <option></option>
                {proxyChoices.map((value, index) => {
                  return <option value={value.id}>{value.name}</option>
                }
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field
                control="input"
                type="checkbox"
                label="Voir sur 10 jours"
                onChange={(e) => {
                  this.setState({ tenDaysDisplay: e.target.checked });
                }}
              >
              </Form.Field>
            </Form.Group>
            <Button positive type="submit">
              Update
            </Button>
          </Form>
        </Segment>
				<canvas id="myChart" width="400" height="200"></canvas>
			</Container>
		);
	}
}

export default TracesChart;