import React, { Fragment, Component } from 'react';
import ReactJson from 'react-json-view';
import {
  List,
  Edit,
  SimpleForm,
  Filter,
  EditButton,
  Create,
  SelectInput,
  ShowGuesser,
  Show,
  SimpleShowLayout,
  NullableBooleanInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  DisabledInput,
  TextInput,
  NumberInput,
  DateInput,
  Datagrid,
  NumberField,
  TextField,
  BooleanField,
  EmailField,
  UrlField,
  DateField,
  ReferenceField,
  BulkDeleteButton
} from 'react-admin';
import { ServerResponse } from '../ServerResponse';

const rowStyle = (record, index) => ({
  backgroundColor: record.active ? '#e6ffe6' : 'white'
});

export const FrequencyProfileEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="identifier" />
      <TextInput source="description" />
      <NumberInput source="frequency" />
      <DisabledInput source="createdAt" />
      <DisabledInput source="updatedAt" />
      <DisabledInput source="id" />
    </SimpleForm>
  </Edit>
);

export const FrequencyProfileCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="identifier" />
      <TextInput source="description" />
      <NumberInput source="frequency" />
    </SimpleForm>
  </Create>
);

export class FrequencyProfilesList extends Component {
  state = { frequencyprofileserverresponse: {} };

  handleserverreponse = response => {
    this.setState({ frequencyprofileserverresponse: response });
  };

  render() {
    const { frequencyprofileserverresponse } = this.state;
    return (
      <Fragment>
        {frequencyprofileserverresponse && <ServerResponse serverresponse={frequencyprofileserverresponse} />}
        <List
          {...this.props}
          perPage={25}
          sort={{ field: 'createAt', order: 'DESC' }}
        >
          <Datagrid rowStyle={rowStyle}>
            <TextField source="identifier" />
            <TextField source="description" />
            <DateField source="createdAt" locales="fr-FR" />
            <EditButton />
          </Datagrid>
        </List>
      </Fragment>
    );
  }
}