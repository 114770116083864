import React, { Component } from 'react';
import { Segment, Message, Form } from 'semantic-ui-react';
import moment from 'moment';
import { SCRAPPER_API, FRONTEND_API_TOKEN } from '../../config';
import 'moment/locale/fr';

moment.locale('fr');

class ApiTokenForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: '',
      password: '',
      error: ''
    };
  }

  handleSubmit = () => {
    console.log('creating apiToken user');
    // todo
    const { login, password } = this.state;
    fetch(`${SCRAPPER_API}/apiTokens`, {
      body: JSON.stringify({ login, password }),
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${FRONTEND_API_TOKEN}`
      }
    })
      .then(res => {
        console.dir(res);
        return res.json();
      })
      .then(res => {
        console.dir(res);
        this.setState({
          error: res.error
        });
        if (!res.error) {
          this.props.onUserCreated(res);
        }
      })
      .catch(err => {
        console.error(err);
        this.setState({
          error: err
        });
      });
  };

  render() {
    return (
      <Segment inverted>
        <Form
          inverted
          onSubmit={this.handleSubmit}
          autoComplete="off"
          error={this.state.error && this.state.error.length > 0}
        >
          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              label="Login"
              placeholder="login"
              onChange={(e, { value }) => {
                this.setState({ login: value });
              }}
            />
            <Form.Input
              required
              type="password"
              fluid
              label="Mot de passe"
              placeholder="password"
              onChange={(e, { value }) => {
                this.setState({ password: value });
              }}
            />
          </Form.Group>
          <Message error header="Error when registering new user" content={`${this.state.error}`} />
          <Form.Button positive>Créer utilisateur d'API</Form.Button>
        </Form>
      </Segment>
    );
  }
}

export default ApiTokenForm;
