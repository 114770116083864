import React, { Component } from 'react';
import { Input, Button, Form } from "semantic-ui-react";
import {FRONTEND_API_TOKEN, SCRAPPER_API} from '../../config';

class impAsin extends Component {
    state = {
        hideButtonUpload: true,
        hideButtonRetry: true
    };

    handleChange = (event) => {
        let button = document.getElementById('uploadButton');
        this.setState({
            csvFile: event.target.files[0]
        });

        if (event.target.files[0])
        {
            button.style.removeProperty("display");
            this.setState({ hideButtonUpload: false });
        }
        else
        {
            button.style.display = 'none';
            this.setState({ hideButtonUpload: true });
        }
    };

    sendCsv = () => {
        if (!this.state)
            return;
        let reader = new FileReader(),
            {csvFile} = this.state,
            button = document.getElementById('uploadButton'),
            inputFile = document.getElementById('impZone');


        button.className = 'ui loading primary button';
        button.innerHTML = 'loading';
        inputFile.disabled = 'true';

        const callOnRead = (e) => {
            const content = JSON.stringify({fileContent: reader.result});
            fetch(`${SCRAPPER_API}/impAsin`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${FRONTEND_API_TOKEN}`,
                    'Content-type': 'application/json'
                },
                body: content
            }).then((response) => {
                let retryButton = document.getElementById('retryButton'),
                    uploadButton = document.getElementById('uploadButton'),
                    messageError = document.getElementById('messageError');

                if (response.ok) {
                    button.className = 'ui green button';
                    button.disabled = true;
                    button.innerHTML = "upload success";
                } else {
                    button.className = 'ui red button';
                    button.disabled = true;
                    button.innerHTML = 'upload failed';
                    if (response.status === 401)
                        messageError.innerHTML= "Le fichier est vide";
                    else if (response.status === 400)
                        messageError.innerHTML = 'Probleme de format dans le fichier, rappel :<br> - 1ere ligne: nom de colonne separés par virgule.<br> - autres lignes: values colonnes separées par virgule';
                    else
                        messageError.innerHTML = 'Error in side server';
                }

                retryButton.style.removeProperty("display");
                this.setState({ hideButtonRetry: false });
                return (response.json())
            }).then((responseJson) => {
                let messageError = document.getElementById('messageError');

                if (responseJson.statusName == 200 || responseJson.statusName == 201) {
                    if (responseJson.statusName == 200)
                        messageError.className = 'ui success message';
                    messageError.innerHTML = responseJson.statusDescription;
                }
                messageError.hidden = false;
            });
        };

        reader.onloadend = callOnRead;
        reader.readAsText(csvFile);
    };

    retry = () => {
        let retryButton = document.getElementById('retryButton'),
            uploadButton = document.getElementById('uploadButton'),
            messageError = document.getElementById('messageError'),
            inputFile = document.getElementById('impZone');

        inputFile.value = null;
        inputFile.disabled = false;

        messageError.hidden = true;
        messageError.className = 'ui error message';

        retryButton.style.display = 'none';
        this.setState({ hideButtonRetry: true });

        uploadButton.className = 'ui blue button';
        uploadButton.innerHTML = 'upload';
        uploadButton.disabled = false;
        uploadButton.style.display = 'none';
        this.setState({ hideButtonUpload: true });
    };


    render()
    {
        const handle_hide = {
            display: "none"
        };

        return (
            <div>
                <Input
                    id='impZone'
                    type="file"
                    name="file"
                    onChange={this.handleChange}
                />
                <button id="uploadButton" className="ui blue button" style={handle_hide} onClick={this.sendCsv}>upload</button>
                <button id="retryButton" className="ui grey button" style={handle_hide} onClick={this.retry}>retry</button>
                <div id="messageError" className="ui error message" hidden></div>
            </div>
        );
    }
}

export default impAsin;