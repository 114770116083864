import React, { Component } from 'react';
import { Divider, Container, Form, Segment, Button, Checkbox, Message } from 'semantic-ui-react';
import { SCRAPPER_API, FRONTEND_API_TOKEN, LIBRINOVA_API } from '../../config';

export default class RequestStatistics extends Component {
  constructor(props) {
		super(props);
    this.state = {
    	freq_fast: 3,
	  	freq_mid: 6,
	  	freq_low: 10,
	  	books_freq_fast: 0,
	  	books_freq_mid: 0,
	  	books_freq_low: 0,

	  	books_freq_fast_librinova: 0,
	  	books_freq_mid_librinova: 0,
	  	books_freq_low_librinova: 0,

	  	nb_amazon_requests: 3,
	  	nb_kobo_requests: 3,

	  	hourly_scraperbots: 0,
	  	daily_scraperbots: 0,
	  	monthly_scraperbots: 0,
	  	kobo_monthly: 0,
	  	amazon_monthly: 0,
	  	scraperbox_kobo_credit_js: 0,
	  	scraperbots_requests_during_last_hour: 0,
	  	scraperbots_requests_during_last_24_hours: 0
    };
	}

  handleserverreponse = response => {
  };

  calculateStats = () => {
  	const daily_scraperbots = this.state.books_freq_fast / this.state.freq_fast +
  		this.state.books_freq_mid / this.state.freq_mid +
  		this.state.books_freq_low / this.state.freq_low;
  	const hourly_scraperbots = daily_scraperbots / 24;
  	const monthly_scraperbots = daily_scraperbots * 30;
  	const kobo_monthly = monthly_scraperbots * this.state.nb_kobo_requests;
  	const amazon_monthly = monthly_scraperbots * this.state.nb_amazon_requests;
  	const scraperbox_kobo_credit_js = kobo_monthly * 5;

  	this.setState({
  		hourly_scraperbots: hourly_scraperbots,
  		daily_scraperbots: daily_scraperbots,
  		monthly_scraperbots: monthly_scraperbots,
  		kobo_monthly: kobo_monthly,
  		amazon_monthly: amazon_monthly,
  		scraperbox_kobo_credit_js: scraperbox_kobo_credit_js
  	})
  };

  componentDidMount() {
  	const librinova_freq_stats_url = LIBRINOVA_API + '/frequencies/frequencies_datas';
  	const scraper_freq_stats_url = SCRAPPER_API + '/getRequestStatisticsInfo';

  	fetch(scraper_freq_stats_url, {
  		method: 'GET'
  	})
  	.then(function(res) {
  		return res.json();
  	})
  	.then(function(res) {
  		this.setState({
  			books_freq_fast: res.statusDescription.fast_frequency,
  			books_freq_mid: res.statusDescription.mid_frequency,
  			books_freq_low: res.statusDescription.low_frequency,

  			scraperbots_requests_during_last_hour: res.statusDescription.scraperbots_requests_during_last_hour,
  			scraperbots_requests_during_last_24_hours: res.statusDescription.scraperbots_requests_during_last_24_hours,

  			freq_fast: res.statusDescription.frequency_fast_in_days,
  			freq_mid: res.statusDescription.frequency_mid_in_days,
  			freq_low: res.statusDescription.frequency_low_in_days
  		})

  		return fetch(librinova_freq_stats_url, {
  			method: 'GET',
  			headers: {
  				'Access-Control-Request-Method': 'GET'
  			}
  		})
  	}.bind(this))
  	.then(function(res) {
  		return res.json();
  	})
  	.then(function(res) {
  		this.setState({
  			books_freq_fast_librinova: res.freq_fast,
  			books_freq_mid_librinova: res.freq_mid,
  			books_freq_low_librinova: res.freq_low
  		})
  	}.bind(this))
  	.catch(function(err) {
  		console.error(err);
  	})
  }

  render() {
    return (
      <Container>
      	<Form
          onSubmit={this.calculateStats.bind(this)}
          autoComplete="off"
          error={this.state.error && this.state.error.length > 0}
        >
        	<Form.Group widths="equal">
        		<Form.Field
              control="input"
              label="Fréquence rapide (jours)"
              placeholder="freq_fast"
              value={this.state.freq_fast}
              onChange={(e) => {
                this.setState({ freq_fast: e.target.value });
              }}
            />
            <Form.Field
              control="input"
              label="Fréquence moyenne (jours)"
              placeholder="freq_mid"
              value={this.state.freq_mid}
              onChange={(e) => {
                this.setState({ freq_mid: e.target.value });
              }}
            />
            <Form.Field
              control="input"
              label="Fréquence lente (jours)"
              placeholder="freq_low"
              value={this.state.freq_low}
              onChange={(e) => {
                this.setState({ freq_low: e.target.value });
              }}
            />
        	</Form.Group>
        	<Form.Group widths="equal">
        		<Form.Field
              control="input"
              label="Nb de livres fréquence rapide"
              placeholder="books_freq_fast"
              value={this.state.books_freq_fast}
              onChange={(e) => {
                this.setState({ books_freq_fast: e.target.value });
              }}
            />
            <Form.Field
              control="input"
              label="Nb de livres fréquence moyenne"
              placeholder="books_freq_mid"
              value={this.state.books_freq_mid}
              onChange={(e) => {
                this.setState({ books_freq_mid: e.target.value });
              }}
            />
            <Form.Field
              control="input"
              label="Nb de livres fréquence lente"
              placeholder="books_freq_low"
              value={this.state.books_freq_low}
              onChange={(e) => {
                this.setState({ books_freq_low: e.target.value });
              }}
            />
        	</Form.Group>
        	<Form.Group widths="equal">
        		<Form.Field
              control="input"
              label="Nb de requêtes Amazon"
              placeholder="nb_amazon_requests"
              value={this.state.nb_amazon_requests}
              onChange={(e) => {
                this.setState({ nb_amazon_requests: e.target.value });
              }}
            />
            <Form.Field
              control="input"
              label="Nb de requêtes Kobo"
              placeholder="nb_amazon_requests"
              value={this.state.nb_kobo_requests}
              onChange={(e) => {
                this.setState({ nb_kobo_requests: e.target.value });
              }}
            />
        	</Form.Group>

        	<Message info>
        		<Message.Item>Librinova service commentaires (rapide) : {this.state.books_freq_fast_librinova}</Message.Item>
        		<Message.Item>Librinova service papier (moyenne) : {this.state.books_freq_mid_librinova}</Message.Item>
        		<Message.Item>Librinova tous les autres livres (lente) : {this.state.books_freq_low_librinova}</Message.Item>
        		<br/>
        		<Message.Item>(Note: Ces résultats sont basés sur le nombre de scraperbots amazon commentaire qui ont été lancés dernièrement)</Message.Item>
        		<Message.Item>Livres scrapés durant la dernière heure : {this.state.scraperbots_requests_during_last_hour}</Message.Item>
        		<Message.Item>Livres scrapés durant les 24 dernières heures : {this.state.scraperbots_requests_during_last_24_hours}</Message.Item>
          </Message>

        	<Button positive type="submit">
            Calculer
          </Button>
        </Form>
        <Message info>
        	<Message.Item>Moyenne de livres scrapés horaire : {this.state.hourly_scraperbots}</Message.Item>
        	<Message.Item>Moyenne de livres scrapés quotidienne : {this.state.daily_scraperbots}</Message.Item>
        	<Message.Item>Moyenne de livres scrapés mensuelles : {this.state.monthly_scraperbots}</Message.Item>
        	<br/>
        	<Message.Item>Requêtes mensuelles kobo : {this.state.kobo_monthly}</Message.Item>
        	<Message.Item>Requêtes mensuelles amazon : {this.state.amazon_monthly}</Message.Item>
        	<br/>
        	<Message.Item>Nb de crédits mensuels scraperbox requis pour kobo : {this.state.scraperbox_kobo_credit_js}</Message.Item>
        </Message>
      </Container>
    );
  }
}