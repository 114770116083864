import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import { SCRAPPER_API } from './config';

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    console.log(`in auth login`);
    const { username, password } = params;
    fetch(`${SCRAPPER_API}/apiTokens/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
	'Access-Control-Allow-Origin':'*'
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify({ login: username, password })
    })
      // .then(res => res.json())
      .then(res => {
        console.log({ res });
        if (res.ok) {
          console.log(`res.ok !`);
          return res.json();
        }
        console.log(`not res.ok!`);
        // throw new Error(res.statusText);
        return Promise.reject();
      })
      .then(res => {
        console.log({ res });
        // token._id, token.login
        console.log(`rest from auth_login`);
        localStorage.setItem('username', username);
        return Promise.resolve({ redirectTo: '/' });
      })
      .catch(err => {
        console.error(err);
        return Promise.reject();
      });
    // accept all username/password combinations
    // console.log(`about to finally resolve`);
    // return Promise.resolve();
  }
  // called when the user clicks on the logout button
  else if (type === AUTH_LOGOUT) {
    console.log(`inside auth logout`);
    localStorage.removeItem('username');
    return Promise.resolve();
  }
  // // called when the API returns an error
  else if (type === AUTH_ERROR) {
    const { status } = params;
    console.log(`inside auth error`);
    if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // // called when the user navigates to a new location
  else if (type === AUTH_CHECK) {
    console.log(`inside authcheck`);
    console.log(localStorage.getItem('username'));

    return localStorage.getItem('username') ? Promise.resolve() : Promise.reject();
  } else if (type === AUTH_GET_PERMISSIONS) {
    console.log(`inside auth_get_permissions!`);
    // const role = localStorage.getItem('role');
    // return role ? Promise.resolve(role) : Promise.reject();
    return Promise.resolve();
  } else {
    console.log(`rejecting with unkown method!`);
    return Promise.reject('Unknown method');
  }
  // return Promise.resolve();
};
