import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Table, Icon, Input } from 'semantic-ui-react';
import moment from 'moment';
import { SCRAPPER_API, FRONTEND_API_TOKEN } from '../../config';
import 'moment/locale/fr';

moment.locale('fr');

class ApiTokenTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledUserIds: [],
      jwt_lifespans: []
    };
  }

  handleDelete = userId => {
    console.log(`deleting + ${userId}`);
    fetch(`${SCRAPPER_API}/apiTokens/${userId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${FRONTEND_API_TOKEN}`
      }
    })
      .then(res => {
        console.dir(res);
        if (res.ok) {
          const disabledUserIds = [userId, ...this.state.disabledUserIds];
          this.setState({ disabledUserIds });
          console.dir({ disabledUserIds, stateDisabled: this.state.disabledUserIds });
        } else {
          // return res.json()
          console.error('Error when deleting');
        }
      })
      .catch(err => {
        console.error(err);
        // todo: handle delete errors
      });
  };

  handleChangeJWTLifespan = (newValue, userId) => {
    console.log(`changing lifespan`);
    const jwt_lifespans = [...this.state.jwt_lifespans];
    jwt_lifespans[userId] = newValue;
    this.setState({ jwt_lifespans });
  };

  renderUserRow = (user, i) => (
    <Table.Row key={i} disabled={this.state.disabledUserIds.includes(user._id)}>
      <Table.Cell>{user.login}</Table.Cell>
      <Table.Cell>
        {user.token && (
          <div>
            <Input
              fluid
              action={
                <CopyToClipboard text={user.token}>
                  <Button icon color="teal">
                    <Icon name="copy" />
                  </Button>
                </CopyToClipboard>
              }
              value={user.token}
            />
            {user.token_iat && <div>Créé le {moment(user.token_iat).format('llll')} </div>}
            {user.token_exp && <div>Expire le {moment(user.token_exp).format('llll')}</div>}
          </div>
        )}
      </Table.Cell>
      <Table.Cell collapsing>
        <Button
          animated="vertical"
          onClick={() => {
            console.log(`new lifespan = ${this.state.jwt_lifespans[user._id]}`);
            this.props.generateToken(user._id, this.state.jwt_lifespans[user._id]);
          }}
        >
          <Button.Content hidden>token</Button.Content>
          <Button.Content visible>
            <Icon name="refresh" />
          </Button.Content>
        </Button>
        <Input
          type="number"
          placeholder="lifespan (secondes)"
          onChange={e => this.handleChangeJWTLifespan(e.target.value, user._id)}
        />
      </Table.Cell>
      <Table.Cell collapsing>
        <Button icon="delete" onClick={() => this.handleDelete(user._id)} />
      </Table.Cell>
    </Table.Row>
  );

  render() {
    console.log({ usersProps: this.props.users });
    return (
      <Table compact celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Login</Table.HeaderCell>
            <Table.HeaderCell>Token (timestamp : heure locale)</Table.HeaderCell>
            <Table.HeaderCell width={1}>Générer Token (JWT)</Table.HeaderCell>
            <Table.HeaderCell width={1}>Supprimer</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.users ? this.props.users.map(this.renderUserRow) : <tr />}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>(timestamp : heure locale)</Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}

export default ApiTokenTable;
