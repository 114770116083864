import React from 'react';
import { Route } from 'react-router-dom';
import Cron from './components/cron/cron.js';
import Dummy from './components/dummy/dummy.js';
import Swagger from './components/swagger/swagger.js';
import ApiTokens from './components/apiTokens/apiTokens.js';
import Tests from './components/tests/tests.js';
import ImpAsin from './components/impAsin/index.js';
import TracesChart from './components/traces/tracesChart.js';
import RequestsStatistics from './components/scraperbots/requestsStatistics';

export default [
  <Route exact path="/cron" component={Cron} />,
  <Route exact path="/dummy" component={Dummy} />,
  <Route exact path="/swagger" component={Swagger} />,
  <Route exact path="/apiTokens" component={ApiTokens} />,
  <Route exact path="/tests" component={Tests} />,
  <Route exact path="/impAsin" component={ImpAsin} />,
  <Route exact path="/tracesChart" component={TracesChart} />,
  <Route exact path="/requests-statistics" component={RequestsStatistics} />,
  // <Route exact path="/bar" component={Bar} />,
  // <Route exact path="/baz" component={Baz} noLayout />
];
