import React from 'react';
import Avatar from '@material-ui/core/Avatar';

export const SiteAvatarField = ({ record = {} }) => {
  console.log({ record });
  const size = 25;
  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
      <Avatar src={`icons/${record.site}.png`} size={size} style={{ width: size, height: size }} />
      &nbsp;{record.site}
    </div>
  );
};
