import React, { Component } from 'react';
import { Divider, Container } from 'semantic-ui-react';
import moment from 'moment';
import ApiTokenForm from './apiTokenForm';
import ApiTokenTable from './apiTokenTable';
import { SCRAPPER_API, FRONTEND_API_TOKEN } from '../../config';
import 'moment/locale/fr';

moment.locale('fr');

class ApiTokens extends Component {
  constructor(props) {
    super(props);
    this.state = { users: [] };
  }

  componentDidMount() {
    fetch(`${SCRAPPER_API}/apiTokens`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${FRONTEND_API_TOKEN}`
      }
    })
      .then(res => {
        console.log({ res });
        return res.json();
      })
      .then(res => {
        console.dir(res);
        this.setState({ users: [...res] });
        console.log('received users');
        console.dir(this.state);
      });
  }

  onUserCreated = user => {
    const users = [user, ...this.state.users];
    this.setState({ users });
  };

  generateToken = (apiTokenUserId, jwt_lifespan) => {
    console.log({ apiTokenUserId, jwt_lifespan });

    fetch(`${SCRAPPER_API}/apiTokens/${apiTokenUserId}/token`, {
      body: JSON.stringify({ jwt_lifespan }),
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${FRONTEND_API_TOKEN}`
        // 'accepts' : 'application/json'
      }
    })
      .then(res => {
        console.dir(res);
        return res.json();
      })
      .then(res => {
        console.dir(res);
        // console.dir(res.body);
        // Update users in state with updated user (fetched from server) with new token
        const users = [...this.state.users];
        users[users.findIndex(element => element._id === apiTokenUserId)] = res;
        this.setState({ users });
        console.dir(this.state);
      })
      .catch(err => {
        console.error(err);
      });

    /* request.post(`${SCRAPPER_API}/users/${apiTokenUserId}/token`).end((err, res) => {
            if (err) {
                console.error(err);
            } else {
                console.dir(res.body);
                // Update users in state with updated user (fetched from server) with new token
                let users = [...this.state.users];
                users[users.findIndex((element) => element._id === apiTokenUserId)] = res.body;
                this.setState({users});
                console.dir(this.state);
                // _id: "5a61b473de6bcc676bec5c72", login: "samy@gmail.com", token: 'asdf4334f4f',
            }
        }); */
  };

  render() {
    return (
      <Container>
        <ApiTokenForm onUserCreated={this.onUserCreated} />
        <Divider />
        <ApiTokenTable users={this.state.users} generateToken={this.generateToken} />
      </Container>
    );
  }
} // End of class

export default ApiTokens;
