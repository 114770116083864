// in src/users.js
import React, { Fragment, Component } from 'react';
import {
  List,
  Edit,
  SimpleForm,
  AutocompleteInput,
  ArrayField,
  ReferenceInput,
  Show,
  SimpleShowLayout,
  BooleanInput,
  DisabledInput,
  TextInput,
  DateInput,
  Datagrid,
  NumberField,
  TextField,
  BooleanField,
  NullableBooleanInput,
  EmailField,
  UrlField,
  DateField,
  ReferenceField,
  Filter,
  SelectInput,
  EditButton,
  CreateController,
  BulkDeleteButton,
  Create
} from 'react-admin';
import { BookInfoField } from '../bookInfoField';
import BulkLaunchButton from './BulkLaunchButton';
import { ServerResponse } from '../ServerResponse';

const Filters = props => (
  <Filter {...props}>
    <NullableBooleanInput label="Active" source="active_boolean" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <TextInput source="email" />
      <TextInput source="name" />
      {/* <TextInput source="externalIds" /> */}
      {/* <DisabledInput source="createdAt" /> */}
      {/* <DisabledInput source="updatedAt" /> */}
      {/* <NumberInput source="__v" /> */}
      {/* <TextInput source="id" /> */}
    </SimpleForm>
  </Create>
);

export const UserEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <TextInput source="email" />
      <TextInput source="name" />
      {/* <TextInput source="externalIds" /> */}
      <DisabledInput source="createdAt" locales="fr-FR" />
      <DisabledInput source="updatedAt" locales="fr-FR" />
      {/* <NumberInput source="__v" /> */}
      {/* <TextInput source="id" /> */}
    </SimpleForm>
  </Edit>
);

export const UserAlertsShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <ArrayField source="alerts">
        <Datagrid>
          <BooleanField source="notified" />
          <ReferenceField label="book" source="book" reference="books">
            <BookInfoField />
          </ReferenceField>
          <TextField source="site" />
          <TextField source="dataType" />
          <TextField source="alertIf.method" />
          <DateField source="createdAt" locales="fr-FR" />
        </Datagrid>
      </ArrayField>
      <ArrayField source="watcherbots">
        <Datagrid>
          <BooleanField source="active" />
          <ReferenceField label="book" source="book" reference="books">
            <BookInfoField />
          </ReferenceField>
          <TextField source="site" />
          <TextField source="dataType" />
          <TextField source="alertIf.method" />
          <DateField source="createdAt" locales="fr-FR" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

const PostBulkActionButtons = ({ handleserverreponse, ...otherProps }) => (
  <Fragment>
    <BulkLaunchButton
      label="Reset Views"
      handleserverreponse={handleserverreponse}
      {...otherProps}
    />
    {/* Add the default bulk delete action */}
    {/* <BulkDeleteButton {...otherProps} /> */}
  </Fragment>
);

const rowStyle = (record, index) => ({
  backgroundColor: record.active ? '#e6ffe6' : 'white'
});

const UrlFieldLibrinova = ({ source, record = {} }) => {
  console.log({ source });
  console.log({ record });
  const link = `https://www.librinova.com/admin/member/info/${
    record[source.split('.')[0]][source.split('.')[1]]
  }`;
  return (
    <a target="_blank" rel="noopener noreferrer" href={link}>
      {record[source.split('.')[0]][source.split('.')[1]]}
    </a>
  );
};
export class UserList extends Component {
  state = { alertsserverresponse: {} };

  handleserverreponse = response => {
    this.setState({ alertsserverresponse: response });
  };

  render() {
    const { alertsserverresponse } = this.state;
    return (
      <Fragment>
        {alertsserverresponse && <ServerResponse serverresponse={alertsserverresponse} />}
        <List
          {...this.props}
          perPage={200}
          sort={{ field: 'createdAt', order: 'DESC' }}
          filters={<Filters />}
          bulkActionButtons={
            <PostBulkActionButtons handleserverreponse={this.handleserverreponse} />
          }
        >
          <Datagrid rowStyle={rowStyle} rowClick="expand" expand={<UserAlertsShow />}>
            {/* <ReferenceField source="_id" reference="s"><TextField source="id" /></ReferenceField> */}
            <BooleanField source="active" />
            <EmailField source="email" />
            <TextField source="name" />
            {/* <ReferenceArrayField source="externalIds" reference="externals"><TextField source="id" /></ReferenceArrayField> */}
            <DateField source="createdAt" locales="fr-FR" />
            <DateField source="updatedAt" locales="fr-FR" />
            <NumberField source="alerts.length" />
            <NumberField source="alertsNotNotified.length" />
            <UrlFieldLibrinova source="externalIds.librinova" />
            {/* <NumberField source="__v" /> */}
            {/* <TextField source="id" /> */}
            <EditButton />
          </Datagrid>
        </List>
      </Fragment>
    );
  }
}
