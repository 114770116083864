// in src/users.js
import React from 'react';
import {
  List,
  Edit,
  SimpleForm,
  AutocompleteInput,
  ReferenceInput,
  BooleanInput,
  DisabledInput,
  TextInput,
  DateInput,
  Datagrid,
  NumberField,
  TextField,
  BooleanField,
  NullableBooleanInput,
  EmailField,
  UrlField,
  DateField,
  ReferenceField,
  Filter,
  SelectInput,
  EditButton,
  CreateController,
  Create
} from 'react-admin';
import { BookInfoField } from '../bookInfoField';
import { SiteAvatarField } from '../siteAvatarField';

const Filters = props => (
  <Filter {...props}>
    <NullableBooleanInput label="Active" source="active_boolean" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);

const rowStyle = (record, index) => ({
  backgroundColor: record.notified ? '#ccc' : 'white'
});

export const AlertList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    perPage={25}
    sort={{ field: 'updatedAt', order: 'DESC' }}
  >
    <Datagrid rowClick="edit" rowStyle={rowStyle}>
      <BooleanField source="notified" />
      <ReferenceField label="book" source="book" reference="books">
        <BookInfoField />
      </ReferenceField>
      <ReferenceField label="user" source="user" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <SiteAvatarField />
      <TextField source="dataType" />
      <TextField source="alertData.alertIfFunction.method" />
      <TextField source="alertIf.method" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      {/* <TextField source="id" /> */}
    </Datagrid>
  </List>
);
