/* eslint-disable react/no-multi-comp */
// in src/users.js
import React, { Fragment, Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import {
  List,
  Edit,
  Create,
  SimpleForm,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  DisabledInput,
  TextInput,
  DateInput,
  Datagrid,
  NumberField,
  TextField,
  BooleanField,
  NullableBooleanInput,
  EditButton,
  EmailField,
  UrlField,
  DateField,
  ReferenceField,
  BulkDeleteButton,
  Button,
  SelectInput
} from 'react-admin';

import BulkLaunchButton from './BulkLaunchButton';
import BulkRefreshButton from './BulkRefreshButton';
import { ServerResponse } from '../ServerResponse';

import { BookInfoField } from '../bookInfoField';
import { SiteAvatarField } from '../siteAvatarField';
import { sitesChoices, datatypesChoices } from '../selectInputs';

const Filters = props => (
  <Filter {...props}>
    <NullableBooleanInput label="Active" source="active_boolean" alwaysOn />
    <ReferenceInput
      label="Book (isbn/title)"
      source="book_byID"
      reference="books"
      alwaysOn
      fullWidth
    >
      <AutocompleteInput
        resettable
        optionText={choice => `${choice.isbn13} - ${choice.title}`}
        ptions={{
          fullWidth: true
        }}
      />
    </ReferenceInput>
    <SelectInput alwaysOn fullWidth label="Site" source="site" choices={sitesChoices} />
    <SelectInput alwaysOn fullWidth label="DataType" source="dataType" choices={datatypesChoices} />
    <DateInput source="dateLastScrap_gte" label="dateLastScrap min" locales="fr-FR" alwaysOn />
    <DateInput source="dateLastScrap_lte" label="dateLastScrap max" locales="fr-FR" alwaysOn />
  </Filter>
);

export const ScraperbotCreate = props => (
  <Create {...props}>
    <SimpleForm>
      {/* <DateInput source="dateLastScrap"  locales="fr-FR"/> */}
      <BooleanInput source="active" />
      <ReferenceInput label="Book (isbn/title)" source="book" reference="books" alwaysOn fullWidth>
        <AutocompleteInput
          resettable
          optionText={choice => `${choice.isbn13} - ${choice.title}`}
          options={{
            fullWidth: true
          }}
        />
      </ReferenceInput>
      <SelectInput label="Site" source="site" choices={sitesChoices} />
      <SelectInput label="DataType" source="dataType" choices={datatypesChoices} />
      {/* <DisabledInput source="createdAt" /> */}
      {/* <DisabledInput source="updatedAt" /> */}
      {/* <DisabledInput source="id" /> */}
    </SimpleForm>
  </Create>
);

export const ScraperbotEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DateInput source="dateLastScrap" locales="fr-FR" />
      <DateInput source="dateLatestData" locales="fr-FR" />
      <BooleanInput source="active" />
      <ReferenceInput label="Book (isbn/title)" source="book" reference="books" alwaysOn fullWidth>
        <AutocompleteInput
          resettable
          optionText={choice => `${choice.isbn13} - ${choice.title}`}
          options={{
            fullWidth: true
          }}
        />
      </ReferenceInput>
      <SelectInput label="Site" source="site" choices={sitesChoices} />
      <SelectInput label="DataType" source="dataType" choices={datatypesChoices} />
      <ReferenceInput label="frequencyProfile" source="frequencyProfile" reference="frequency-profiles">
        <AutocompleteInput
          optionText="identifier"
        />
      </ReferenceInput>
      <DisabledInput source="createdAt" />
      <DisabledInput source="updatedAt" />
      <DisabledInput source="id" />
    </SimpleForm>
  </Edit>
);

const PostBulkActionButtons = ({ handleserverreponse, ...otherProps }) => (
  <Fragment>
    <BulkLaunchButton
      label="Reset Views"
      handleserverreponse={handleserverreponse}
      {...otherProps}
    />
    {/*<BulkRefreshButton
      label="Reset Views"
      handleserverreponse={handleserverreponse}
      {...otherProps}
    />*/}
    {/* Add the default bulk delete action */}
    {/* <BulkDeleteButton {...otherProps} /> */}
  </Fragment>
);

const rowStyle = (record, index) => ({
  backgroundColor: record.active ? '#e6ffe6' : 'white'
});

export class ScraperbotList extends Component {
  state = { scraperbotsserverresponse: {} };

  handleserverreponse = response => {
    this.setState({ scraperbotsserverresponse: response });
  };

  render() {
    const { scraperbotsserverresponse } = this.state;
    return (
      <Fragment>
        {scraperbotsserverresponse && <ServerResponse serverresponse={scraperbotsserverresponse} />}
        <List
          {...this.props}
          perPage={25}
          sort={{ field: 'dateLatestData', order: 'DESC' }}
          filters={<Filters />}
          bulkActionButtons={
            <PostBulkActionButtons handleserverreponse={this.handleserverreponse} />
          }
          //   aside={<Aside scraperbotsserverresponse={scraperbotsserverresponse} />}
        >
          <Datagrid rowStyle={rowStyle}>
            <BooleanField source="active" />
            <ReferenceField label="book" source="book" reference="books">
              <BookInfoField />
            </ReferenceField>
            <SiteAvatarField />
            {/* <TextField source="site" /> */}
            <TextField source="dataType" />
            <DateField source="dateLastScrap" locales="fr-FR" />
            <DateField source="dateLatestData" locales="fr-FR" />
            <DateField source="createdAt" locales="fr-FR" />
            <DateField source="updatedAt" locales="fr-FR" />
            {/* <TextField source="id" /> */}
            <EditButton />
          </Datagrid>
        </List>
      </Fragment>
    );
  }
}

// export const ScraperbotList = props =>;
