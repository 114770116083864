// in src/users.js
import React, { Fragment, Component, cloneElement} from 'react';
import {
  List,
  Edit,
  SimpleForm,
  AutocompleteInput,
  ArrayField,
  ReferenceInput,
  Show,
  SimpleShowLayout,
  BooleanInput,
  DisabledInput,
  TextInput,
  DateInput,
  DateTimeInput,
  Datagrid,
  NumberField,
  TextField,
  BooleanField,
  NullableBooleanInput,
  EmailField,
  UrlField,
  DateField,
  ReferenceField,
  Filter,
  SelectInput,
  EditButton,
  CreateController,
  BulkDeleteButton,
  Create,
  ExportButton,
  CardActions
} from 'react-admin';
import { BookInfoField } from '../bookInfoField';
import { ServerResponse } from '../ServerResponse';
import { sitesChoices, datatypesChoices, statusChoices, proxyChoices } from '../selectInputs';

const ListActions = ({
  className,
  displayedFilters,
  filters,
  showFilter,
  maxResults,
  exporter,
  total,
  resource,
  currentSort,
  filterValues,
  permanentFilter,
  ...rest
}) => (
  maxResults = 10000,

  <CardActions className={className}>
    {filters && cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button'
    })}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={{ ...filterValues, ...permanentFilter }}
      exporter={exporter}
      maxResults={maxResults}
    />
  </CardActions>
);

const Filters = props => (
  <Filter {...props}>
    {/* <NullableBooleanInput label="Active" source="active_boolean" alwaysOn /> */}
    {/* <ReferenceInput
      label="Book (isbn/title)"
      source="book.isbn13"
      reference="books"
      alwaysOn
      fullWidth
    >
      <AutocompleteInput
        resettable
        optionText={choice => `${choice.isbn13} - ${choice.title}`}
        options={{
          fullWidth: true
        }}
      />
    </ReferenceInput> */}
    <TextInput alwaysOn label="isbn" source="isbn" />
    <SelectInput alwaysOn fullWidth label="Site" source="site" choices={sitesChoices} />
    <SelectInput alwaysOn fullWidth label="DataType" source="dataType" choices={datatypesChoices} />
    <SelectInput alwaysOn fullWidth label="Status" source="status" choices={statusChoices} />
    <SelectInput alwaysOn fullWidth label="Proxy" source="proxy" choices={proxyChoices} />
    <DateTimeInput source="date_gte" label="date min" locales="fr-FR" />
    <DateTimeInput source="date_lte" label="date max" locales="fr-FR" />
  </Filter>
);
const rowStyle = (record, index) => ({
  backgroundColor: record.active ? '#e6ffe6' : 'white'
});

export const TraceList = props => (
  <List {...props} perPage={100} sort={{ field: 'date', order: 'DESC' }} filters={<Filters />} actions={<ListActions/>}>
    <Datagrid rowClick="edit">
      <TextField source="isbn" />
      <TextField source="dataType" />
      <TextField source="site" />
      <DateField source="date" locales="fr-FR" showTime />
      <TextField source="level" />
      <TextField source="function" />
      <DateField source="startDate" locales="fr-FR" showTime />
      <NumberField source="status" />
      <TextField source="error" />
      <TextField source="proxy" />
      {/* <DateField source="createdAt" locales="fr-FR" /> */}
      {/* <DateField source="updatedAt" locales="fr-FR" /> */}
    </Datagrid>
  </List>
);
