import React from 'react';

export const BookInfoField = ({ record = {} }) => {
  console.log({ record });
  return (
    <span>
      {record.title}
      <br />
      {record.isbn13}
    </span>
  );
};
