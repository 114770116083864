import React from 'react';
import { SelectInput } from 'react-admin';

export const sitesChoices = [
  { id: 'amazon', name: 'Amazon' },
  { id: 'babelio', name: 'Babelio' },
  { id: 'decitre', name: 'Décitre' },
  { id: 'fnac', name: 'Fnac' },
  { id: 'goodreads', name: 'Goodreads' },
  { id: 'kobo', name: 'Kobo' },
  { id: 'librinova', name: 'librinova' }
];

export const datatypesChoices = [
  { id: 'comments', name: 'Comments' },
  { id: 'statistics', name: 'Statistics' },
  { id: 'formats', name: 'Formats' }
];

export const statusChoices = [
  { id: '200', name: '200 - OK' },
  { id: '204', name: '204 - No Content' },
  { id: '400', name: '400 - Bad Request' },
  { id: '404', name: '404 - Book Not' },
  { id: '500', name: '500 - Scraper Error' },
  { id: '501', name: '501 - Not Implemented' },
  { id: '502', name: '502 - Site Error' }
];

export const proxyChoices = [
	{ id: 'scraperbox', name: 'scraperbox' },
	{ id: 'luminati', name: 'luminati' },
	{ id: 'none', name: 'none' }
];

export const SelectSiteInput = (
  <SelectInput
    alwaysOn
    fullWidth
    label="Site"
    source="site"
    choices={[
      { id: 'amazon', name: 'Amazon' },
      { id: 'babelio', name: 'Babelio' },
      { id: 'decitre', name: 'Décitre' },
      { id: 'fnac', name: 'Fnac' },
      { id: 'goodreads', name: 'Goodreads' },
      { id: 'kobo', name: 'Kobo' },
      { id: 'librinova', name: 'librinova' }
    ]}
  />
);

export const SelectDataTypeInput = (
  <SelectInput
    alwaysOn
    fullWidth
    label="DataType"
    source="dataType"
    choices={[{ id: 'comments', name: 'Comments' }, { id: 'statistics', name: 'Statistics' }]}
  />
);
