// in src/users.js
import React from 'react';
import {
  List,
  Edit,
  Create,
  EditButton,
  SimpleForm,
  Filter,
  ArrayField,
  SingleFieldList,
  ChipField,
  BooleanInput,
  DisabledInput,
  NullableBooleanInput,
  TextInput,
  DateInput,
  Datagrid,
  NumberField,
  TextField,
  BooleanField,
  FunctionField,
  EmailField,
  UrlField,
  DateField,
  ReferenceField,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  Show,
  SimpleShowLayout
} from 'react-admin';
import { BookInfoField } from '../bookInfoField';
import { SiteAvatarField } from '../siteAvatarField';
import { sitesChoices, datatypesChoices } from '../selectInputs';

const Filters = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Book (isbn/title)"
      source="book_byID"
      reference="books"
      alwaysOn
      fullWidth
    >
      <AutocompleteInput
        resettable
        optionText={choice => `${choice.isbn13} - ${choice.title}`}
        ptions={{
          fullWidth: true
        }}
      />
    </ReferenceInput>
    <SelectInput alwaysOn fullWidth label="Site" source="site" choices={sitesChoices} />
    {/* <NullableBooleanInput label="Paper" source="formats_boolean.paper" alwaysOn />
    <NullableBooleanInput label="Ebook" source="formats_boolean.ebook" alwaysOn /> */}
    <DateInput source="updatedAt_gte" label="updatedAt min" locales="fr-FR" alwaysOn />
    <DateInput source="updateAt_lte" label="updateAt max" locales="fr-FR" alwaysOn />
  </Filter>
);

export const ScrapedStatisticCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Book (isbn/title)" source="book" reference="books" alwaysOn fullWidth>
        <AutocompleteInput
          resettable
          optionText={choice => `${choice.isbn13} - ${choice.title}`}
          options={{
            fullWidth: true
          }}
        />
      </ReferenceInput>
      <SelectInput label="Site" source="site" choices={sitesChoices} />
      <NumberInput source="comments_count" />
      <NumberInput source="ratings.value" />
      <NumberInput source="ratings.count" />
      <TextInput source="url" />
    </SimpleForm>
  </Create>
);

export const ScrapedStatisticEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput label="Book (isbn/title)" source="book" reference="books" alwaysOn fullWidth>
        <AutocompleteInput
          resettable
          optionText={choice => `${choice.isbn13} - ${choice.title}`}
          options={{
            fullWidth: true
          }}
        />
      </ReferenceInput>
      <SelectInput label="Site" source="site" choices={sitesChoices} />
      <NumberInput source="comments_count" />
      <NumberInput source="ratings.value" />
      <NumberInput source="ratings.count" />
      <TextInput source="url" />
    </SimpleForm>
  </Edit>
);

export const ScrapedFormatList = props => (
  <List
    {...props}
    perPage={25}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    filters={<Filters />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <ReferenceField label="book" source="book" reference="books">
        <BookInfoField />
      </ReferenceField>
      <SiteAvatarField />
      <BooleanField source="formats.paper" />
      <BooleanField source="formats.ebook" />
      <BooleanField source="formats.unlimited" />
      <UrlField source="url" />
      <DateField source="createdAt" locales="fr-FR" />
      <DateField source="updatedAt" locales="fr-FR" />
      <EditButton />
    </Datagrid>
  </List>
);
