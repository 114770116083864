const ENV = 'PROD'; // PREPROD LOCAL
const DOMAIN =
  ENV === 'PROD'
    ? 'scrapper-api.librinova.com'
    : ENV === 'PREPROD'
    ? 'text2017.librinova.com'
    : 'localhost';
const SERVER =
  ENV === 'PROD'
    ? 'https://scrapper-api.librinova.com'
    : ENV === 'PREPROD'
    ? 'http://text2017.librinova.com:8181'
    : 'http://localhost:8181';
const SCRAPPER_API =
  ENV === 'PROD'
    ? 'https://scrapper-api.librinova.com/api'
    : ENV === 'PREPROD'
    ? 'http://text2017.librinova.com:8181/api'
    : 'http://localhost:8181/api';
const LIBRINOVA_API =
	ENV === 'PROD'
		? 'https://www.librinova.com/api'
		: ENV === 'PREPROD'
		? 'https://preprodv3.librinova.com/api'
		: 'http://librinova.vm/api'
const DEBUG_MODE = ENV !== 'PROD';

// Icones : https://react.semantic-ui.com/elements/icon/
// PAGES is an array of pages grouped by category
const PAGES = [
  {
    header: 'Livres',
    pages: [
      {
        name: 'Livres',
        group: 'Livres',
        description: `Recherche de livres dans notre BDD`,
        path: 'books',
        icon: 'book'
      }
    ]
  },
  {
    header: 'Users',
    pages: [
      {
        name: 'Users',
        group: 'Users',
        description: `Liste des utilisateurs`,
        path: 'users',
        icon: 'user'
      }
    ]
  },
  {
    header: 'Scraperbots',
    pages: [
      {
        name: 'Scraperbots',
        group: 'Scraperbots',
        description: 'Liste des bots de scraping',
        path: 'scraperbots',
        icon: 'sitemap'
      },
      {
        name: 'Comments',
        group: 'Scraperbots',
        description: 'Liste des commentaires scrapées',
        path: 'scraped-datas/comments',
        icon: 'database'
      },
      {
        name: 'Statistics',
        group: 'Scraperbots',
        description: 'Liste des statistiques scrapées',
        path: 'scraped-datas/statistics',
        icon: 'database'
      },
      {
        name: 'Formats',
        group: 'Scraperbots',
        description: 'Liste des disponibilités formats scrapées',
        path: 'scraped-datas/formats',
        icon: 'database'
      }
    ]
  },
  {
    header: 'Watcherbots',
    pages: [
      {
        name: 'Watcherbots',
        group: 'Watcherbots',
        description: 'Liste des bots de watching',
        path: 'watcherbots',
        icon: 'eye'
      }
    ]
  },
  {
    header: 'Alerts',
    pages: [
      {
        name: 'Alerts',
        group: 'Alerts',
        description: 'Liste des alertes créés les watcherbots',
        path: 'alerts',
        icon: 'alarm'
      },
      {
        name: 'Launch',
        group: 'Alerts',
        description: 'Traitement des alertes',
        path: 'alerts/launch',
        icon: 'alarm'
      }
    ]
  },
  {
    header: 'Cron',
    pages: [
      {
        name: 'Cron',
        group: 'Cron',
        description: 'Cron',
        path: 'cron',
        icon: 'eye'
      }
    ]
  },

  // {
  //   header: 'Watcherbots',
  //   pages: [
  //     {
  //       name: 'list',
  //       group: 'Watcherbots',
  //       description: 'Liste des bots de scraping',
  //       path: 'watcherbots',
  //       icon: 'alarm'
  //     },
  //     {
  //       name: 'logs',
  //       group: 'Watcherbots',
  //       description: `Logs des bots de scraping`,
  //       path: 'watcherbots/logs',
  //       icon: 'users'
  //     }
  //   ]
  // },
  // {
  //   header: 'Mailer',
  //   pages: [
  //     {
  //       name: 'Robots notif',
  //       group: 'Mailer',
  //       description: `Logs d'envoi des mails de suivi`,
  //       path: 'email-notification',
  //       icon: 'mail'
  //     },
  //   ]
  // },
  // {
  //   header: 'API',
  //   pages: [
  //     {
  //       name: 'sandbox',
  //       group: 'API',
  //       description: 'API librinova scraper',
  //       path: 'api',
  //       icon: 'alarm'
  //     },
  //     {
  //       name: 'utilisateurs',
  //       group: 'API',
  //       description: `Utilisateurs de notre API`,
  //       path: 'users',
  //       icon: 'users'
  //     }
  //   ]
  // },
  {
    header: 'Test',
    pages: [
      // {
      //   name: 'Robots scrapers',
      //   group: 'Reporting',
      //   description: 'Log des robots de scraping',
      //   path: 'alert-notification',
      //   icon: 'comments'
      // },
      // {
      //   name: 'Robots scrapers - stats',
      //   group: 'Reporting',
      //   description: `Monitor des scrapers, pour suivre leur bon fonctionnement`,
      //   path: 'monitoring-scrapers',
      //   icon: 'eye'
      // },

      {
        name: 'Traces',
        group: 'Reporting',
        description: `Traces d'execution`,
        path: 'trace-history',
        icon: 'tasks'
      },
      // {
      //   name: 'Proxies',
      //   group: 'Reporting',
      //   description: 'Logs des erreurs de proxies',
      //   path: 'proxies',
      //   icon: 'protect'
      // },
      {
        name: 'Suite de tests',
        group: 'Test',
        description: `Lancer les tests scrapers et afficher le rapport d'éxecution`,
        path: 'test',
        icon: 'stopwatch'
      }
    ]
  } // 'Robustesse' : {'description' : `Test de robustesse de nos scrapeurs`, 'path' :  'robust', 'icon' : 'wrench'},
];

if (DEBUG_MODE) {
  PAGES[6].pages.push({
    name: 'Dummy',
    group: 'Test',
    description: `Page permettant de rajouter des commentaires fictifs`,
    path: 'dummy',
    icon: 'edit'
  });
}

const PAGES_OBJ = {};

PAGES.forEach(group => {
  group.pages.forEach(page => {
    PAGES_OBJ[page.group + page.name] = page;
  });
});
console.log(PAGES_OBJ);

// const FRONTEND_API_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVjYWIyMDMxMzMxNmI2MDZjZjA4YmQ1YSIsImlhdCI6MTU5ODAxNDI1MiwiZXhwIjoxNjI5NTUwMjUyfQ.c6jfDm5JWCyazCr2FzEEVazapPrg0HvhM7fYcn6Ik6E`;
// const FRONTEND_API_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVjYWIyMDMxMzMxNmI2MDZjZjA4YmQ1YSIsImlhdCI6MTYzMDA1OTc4NiwiZXhwIjoxNjYxNTk1Nzg2fQ.920FJQlHmDWtWy-HpqxDfhK1xaQAupWfLm4wuciiiCA`;
//const FRONTEND_API_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVjYWIxZWJhMzMxNmI2MDZjZjA4YmM2YiIsImlhdCI6MTY1NDE1OTUwNiwiZXhwIjoxNjg1Njk1NTA2fQ.q-ISn2X2YKmsSWtrRcKer0KubzLPXZnR2L_ZUXZvDl4`;
const FRONTEND_API_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVjYWIyMDMxMzMxNmI2MDZjZjA4YmQ1YSIsImlhdCI6MTcxNzUxMzI5NSwiZXhwIjoxNzQ5MDQ5Mjk1fQ.PnI9puu4YdvvQe9qJPudzc0iNIcOKyj3U0I12BPZ1d4`;

export { FRONTEND_API_TOKEN, DEBUG_MODE, ENV, SERVER, SCRAPPER_API, PAGES, DOMAIN, PAGES_OBJ, LIBRINOVA_API };
