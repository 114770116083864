import React, { Component } from 'react';
import { Button } from 'react-admin';

import LaunchIcon from '@material-ui/icons/PlayArrow';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { SCRAPPER_API, FRONTEND_API_TOKEN } from '../../config';

export default class BulkLaunchButton extends React.Component {
  state = { loading: false, dryRun: true };

  handleClick = () => {
    const { basePath, startUndoable, resource, selectedIds } = this.props;
    console.log(`my props :`);

    this.setState({ loading: true });

    fetch(`${SCRAPPER_API}/watcherbots/launch`, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify({ watcherbotsIds: selectedIds, dryRun: this.state.dryRun }), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${FRONTEND_API_TOKEN}`
      }
    })
      .then(res => res.json())
      .then(response => {
        console.log('Success:', JSON.stringify(response));
        this.props.handleserverreponse(response);
      })
      .catch(error => {
        console.error('Error:', error);
        this.props.handleserverreponse(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
    console.log({ props: this.props });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  toggleDryRun = () => {
    this.setState({
      dryRun: !this.state.dryRun
    });
  };

  render() {
    return (
      <FormGroup row={false}>
        <Button label="Lancer ces watcherbots" onClick={this.handleClick}>
          {/* <LaunchIcon /> */}
          {this.state.loading ? <CircularProgress /> : <LaunchIcon />}
        </Button>
        <FormControlLabel
          control={
            <Switch checked={this.state.dryRun} onChange={this.toggleDryRun} value="checkedA" />
          }
          label="dry run"
        />
      </FormGroup>
    );
  }
}
