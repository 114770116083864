// in src/users.js
import React, { Component } from 'react';
import {
  List,
  Edit,
  SimpleForm,
  Filter,
  BooleanInput,
  DisabledInput,
  TextInput,
  DateInput,
  Datagrid,
  NumberField,
  TextField,
  BooleanField,
  EmailField,
  UrlField,
  DateField,
  ReferenceField,
  Create,
  EditButton,
  NullableBooleanInput
} from 'react-admin';
import { SCRAPPER_API } from '../config';
import MyUrlField from '../MyUrlField';

const Filters = props => (
  <Filter {...props}>
    <TextInput label="isbn" source="isbn13" alwaysOn />
    <TextInput label="title" source="title" alwaysOn />
    <TextInput label="authors" source="authors" />
    <NullableBooleanInput label="Has asin?" source="websiteIdentifiers_exists.asin" alwaysOn />
    <TextInput label="asin" source="websiteIdentifiers.asin" />
    <NullableBooleanInput label="Has fnacId?" source="websiteIdentifiers_exists.fnacId" alwaysOn />
    <TextInput label="fnacId" source="websiteIdentifiers.fnacId" />
  </Filter>
);

export const BookCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="authors" />
      <TextInput source="isbn13" />
      <TextInput source="websiteIdentifiers.paperisbn" />
      <TextInput source="title" />
      <TextInput source="websiteIdentifiers.asin" />
      <TextInput source="websiteIdentifiers.paperasin" />
      <TextInput source="websiteIdentifiers.fnacId" />
      <DateInput source="publishedDate" locales="fr-FR" />
    </SimpleForm>
  </Create>
);

export const BookEdit = props => {
  const handleClick = () => {
    const isbn13 = document.querySelector("#isbn13").value
    fetch(SCRAPPER_API + '/scraperbots/getAsin?isbn=' + isbn13)
      .then(response => {
        response.json().then(data => {
          // Need to focus on the input field so react-admin can detect the change of value
          document.querySelector("#websiteIdentifiers\\.asin").focus();
          document.querySelector("#websiteIdentifiers\\.asin").value = data;
        });
      })
      .catch(error => {
        console.error(error);
      })
  };

  return (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="authors" />
      <TextInput source="isbn13" />
      <TextInput source="websiteIdentifiers.paperisbn" />
      <TextInput source="title" />
      <TextInput source="websiteIdentifiers.asin" />
      <button onClick={handleClick}>Search asin</button>
      <TextInput source="websiteIdentifiers.paperasin" />
      <TextInput source="websiteIdentifiers.fnacId" />
      <DateInput source="publishedDate" locales="fr-FR" />
      <DisabledInput source="createdAt" locales="fr-FR" />
      <DisabledInput source="updatedAt" locales="fr-FR" />
      <DisabledInput source="id" />
    </SimpleForm>
  </Edit>
  );
}

export const BookList = props => (
  <List
    {...props}
    perPage={25}
    sort={{ field: 'publishedDate', order: 'DESC' }}
    filters={<Filters />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="isbn13" />
      <TextField source="title" />
      <TextField source="authors" />
      <TextField source="websiteIdentifiers.asin" label="asin" />
      <TextField source="websiteIdentifiers.fnacId" label="fnacId" />
      <TextField source="websiteIdentifiers.fnacUrl" label="fnacUrl" />
      {/* <TextField source="publisher" /> */}
      <DateField source="publishedDate" locales="fr-FR" />
      {/* <DateField source="createdAt" /> */}
      {/* <DateField source="updatedAt" /> */}
      {/* <TextField source="id" /> */}
      <EditButton />
    </Datagrid>
  </List>
);
