import React, { Component } from 'react';
import faker from 'faker/locale/fr';

import { Button, Comment, Form, Header, Segment, Icon, Image, Container } from 'semantic-ui-react';
import './dummy.css';
import moment from 'moment';
import { DEBUG_MODE, SCRAPPER_API, FRONTEND_API_TOKEN } from '../../config';

const DummyComments = props => (
  <Segment id={`commentsFor_${props.isbn}`}>
    {props.comments.map((comment, i) => {
      const key = props.isbn + i;
      const ratingStyle = {
        float: 'right',
        display: 'inline-block',
        margin: '0px 5px 0px 0px',
        fontStyle: 'italic',
        color: 'purple'
      };
      return (
        <Comment key={key}>
          <Comment.Content>
            <Comment.Author as="a">{comment.title}</Comment.Author>
            <Comment.Metadata>
              <div>Publié le {comment.date}</div>
            </Comment.Metadata>
            <Comment.Text>
              {comment.text}
              <h4 className="rating" style={ratingStyle}>
                {comment.rating}/5
              </h4>
            </Comment.Text>
            <Comment.Actions>
              <Comment.Action onClick={() => props.handleChangeDeleteComment(props.isbn, i)}>
                Delete
              </Comment.Action>
            </Comment.Actions>
          </Comment.Content>
        </Comment>
      );
    })}
  </Segment>
);

export default class DummyWebsite extends Component {
  constructor(props) {
    super(props);
    // console.log(`Comments data here = `);
    // console.dir(commentsData);

    this.state = {
      bookList: [],
      bookComments: {},
      newCommentRating: 0,
      newCommentText: '',
      newCommentTitle: '',
      newCommentISBN: '',
      newCommentSite: 'dummy',
      sites: [],
      comments: {}
    };
  }

  generateRandomComment() {
    let randomISBN = '979';
    for (let i = 0; i < 10; i++) {
      randomISBN += Math.floor(Math.random() * 10);
    }

    this.setState({
      newCommentRating: Math.floor(Math.random() * 6),
      newCommentText: faker.lorem.paragraph(),
      newCommentTitle: faker.company.catchPhrase(),
      newCommentISBN: `${randomISBN}`,
      newCommentSite: 'dummy'
    });
  }

  clearComment() {
    this.setState({
      newCommentRating: 0,
      newCommentText: '',
      newCommentTitle: '',
      newCommentISBN: '',
      newCommentSite: 'dummy'
    });
  }

  componentDidMount() {
    document.title = 'DUMMY SITE';
    this.generateRandomComment();
    console.log(`DEBUG_MODE HERE = ${DEBUG_MODE}`);
    // todo: if PROD, fetch PREPROD file instead
    fetch(`${SCRAPPER_API}/sites`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${FRONTEND_API_TOKEN}`
      }
    })
      .then(res => res.json())
      .then(sites => {
        this.setState({ sites: sites.sites });
        return fetch(`${SCRAPPER_API}/dummy/comments`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${FRONTEND_API_TOKEN}`
          }
        });
      })
      .then(res => res.json())
      .then(obj => {
        console.dir(obj);
        this.setState({ comments: obj });
      })
      .catch(err => {
        console.error(err);
      });
  }

  getAverage(comments) {
    const count = comments.length;
    const sum = comments.reduce((a, b) => {
      const bvalue = +b.rating;
      let avalue = a;
      if (typeof a.rating !== 'undefined') {
        avalue = +a.rating;
      }
      return avalue + bvalue;
    }, 0);
    const average = sum / count;

    return average.toFixed(1);
  }

  onEditBookComments(obj) {
    // update sites ??
    console.dir({ updated_src: obj.updated_src });
    fetch(`${SCRAPPER_API}/dummy/comments`, {
      body: JSON.stringify(obj.updated_src), // data can be `string` or {object}!
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${FRONTEND_API_TOKEN}`
      }),
      method: 'PUT'
    })
      .then(res => res.json())
      .then(updatedComments => {
        this.setState({
          comments: updatedComments
        });
      })
      .catch(err => {
        console.error(`Error in onEditBookCOmments `, err);
      });
    return true;
  }

  handleChangeNewCommentISBN = event => {
    this.setState({ newCommentISBN: event.target.value });
  };

  handleChangeNewCommentTitle = event => {
    this.setState({ newCommentTitle: event.target.value });
  };

  handleChangeNewCommentText = event => {
    this.setState({ newCommentText: event.target.value });
  };

  handleChangeNewCommentRating = event => {
    this.setState({ newCommentRating: event.target.value });
  };

  handleChangeNewCommentSite = event => {
    this.setState({ newCommentSite: event.target.value });
  };

  handleChangeDeleteComment = (site, isbn, key) => {
    const updatedCommentsObject = this.state.comments;
    updatedCommentsObject[site][isbn].splice(key, 1);
    this.onEditBookComments({ updated_src: updatedCommentsObject });
  };

  handleDeleteISBN = (site, isbn) => {
    const updatedCommentsObject = this.state.comments;
    delete updatedCommentsObject[site][isbn];
    this.onEditBookComments({ updated_src: updatedCommentsObject });
  };

  handleSubmitNewComment = event => {
    event.preventDefault();
    const updatedCommentsObject = this.state.comments;
    if (!updatedCommentsObject[this.state.newCommentSite]) {
      updatedCommentsObject[this.state.newCommentSite] = {};
    }
    if (!updatedCommentsObject[this.state.newCommentSite][this.state.newCommentISBN]) {
      updatedCommentsObject[this.state.newCommentSite][this.state.newCommentISBN] = [];
    }
    updatedCommentsObject[this.state.newCommentSite][this.state.newCommentISBN].push({
      isbn: this.state.newCommentISBN,
      rating: this.state.newCommentRating,
      text: this.state.newCommentText,
      title: this.state.newCommentTitle,
      date: moment().format('DD/MM/YYYY')
    });
    console.dir({ updatedCommentsObject });
    this.onEditBookComments({ updated_src: updatedCommentsObject });
  };

  displaySiteComments = site => {
    const isbns = Object.keys(this.state.comments[site]);
    return isbns.map(isbn => (
      <Comment.Group key={`${isbn}`}>
        <Header as="h3" dividing>
          {site !== 'dummy' ? <Image src={`/icons/${site}.png`} avatar /> : ''}
          {`${site} : ${isbn}`}{' '}
          <Icon onClick={() => this.handleDeleteISBN(site, isbn)} color="red" name="remove" />
        </Header>
        <p className="dummy_statistics">
          <strong>Moyenne</strong>:{' '}
          <span className="average_rating_value">
            {this.getAverage(this.state.comments[site][isbn])}
          </span>
          /5.0 (
          <span className="ratings_count_value">{this.state.comments[site][isbn].length}</span>{' '}
          notes -&nbsp;
          <span className="comments_count_value">
            {this.state.comments[site][isbn].length}
          </span>{' '}
          commentaires)
        </p>
        <DummyComments
          isbn={isbn}
          comments={this.state.comments[site][isbn]}
          handleChangeDeleteComment={(isbn, key) => this.handleChangeDeleteComment(site, isbn, key)}
        />
      </Comment.Group>
    ));
  };

  displayComments = site => (
    <div className="siteComments" key={site}>
      {this.displaySiteComments(site)}
    </div>
  );

  render() {
    const SITES = Object.keys(this.state.comments);
    if (!DEBUG_MODE) {
      return (
        <div>
          <h1>Cette page n'est pas accessible en production</h1>
        </div>
      );
    }
    return (
      <Container>
        <Segment inverted>
          <Form inverted onSubmit={this.handleSubmitNewComment}>
            <Form.Group widths="equal">
              <Form.Field
                label="site"
                control="select"
                value={this.state.newCommentSite}
                onChange={this.handleChangeNewCommentSite}
              >
                {this.state.sites.map(site => (
                  <option value={site} key={site}>
                    {site}
                  </option>
                ))}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="ISBN"
                placeholder="ISBN"
                value={this.state.newCommentISBN}
                onChange={this.handleChangeNewCommentISBN}
              />
              <Form.Field
                label="rating"
                control="select"
                value={this.state.newCommentRating}
                onChange={this.handleChangeNewCommentRating}
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </Form.Field>
            </Form.Group>
            <Form.Input
              fluid
              label="comment title"
              placeholder="title"
              value={this.state.newCommentTitle}
              onChange={this.handleChangeNewCommentTitle}
            />
            <Form.Field
              label="text"
              control="textarea"
              rows="4"
              value={this.state.newCommentText}
              onChange={this.handleChangeNewCommentText}
            />
            <Button positive type="submit">
              Submit
            </Button>
            <Button icon labelPosition="right" onClick={() => this.generateRandomComment()}>
              Randomize
              <Icon name="random" />
            </Button>
            <Button icon labelPosition="right" onClick={() => this.clearComment()}>
              Clear
              <Icon name="erase" />
            </Button>
          </Form>
        </Segment>

        <div id="book_list">{SITES.map(site => this.displayComments(site))}</div>
      </Container>
    );
  }
}
