// in src/users.js
import React, { Fragment, Component } from 'react';
import ReactJson from 'react-json-view';
import {
  List,
  Edit,
  SimpleForm,
  Filter,
  EditButton,
  Create,
  SelectInput,
  ShowGuesser,
  Show,
  SimpleShowLayout,
  NullableBooleanInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  DisabledInput,
  TextInput,
  DateInput,
  Datagrid,
  NumberField,
  TextField,
  BooleanField,
  EmailField,
  UrlField,
  DateField,
  ReferenceField,
  BulkDeleteButton
} from 'react-admin';
import { BookInfoField } from '../bookInfoField';
import { SiteAvatarField } from '../siteAvatarField';
import { sitesChoices, datatypesChoices } from '../selectInputs';

import BulkLaunchButton from './BulkLaunchButton';
import { ServerResponse } from '../ServerResponse';

const Filters = props => (
  <Filter {...props}>
    <NullableBooleanInput label="Active" source="active_boolean" alwaysOn />
    <ReferenceInput
      label="Book (isbn/title)"
      source="book_byID"
      reference="books"
      alwaysOn
      fullWidth
    >
      <AutocompleteInput
        resettable
        optionText={choice => `${choice.isbn13} - ${choice.title}`}
        options={{
          fullWidth: true
        }}
      />
    </ReferenceInput>
    <ReferenceInput
      label="User (email/name)"
      source="user_byID"
      reference="users"
      alwaysOn
      fullWidth
    >
      <AutocompleteInput
        resettable
        optionText={choice => `${choice.email} - ${choice.name}`}
        options={{
          fullWidth: true
        }}
      />
    </ReferenceInput>
    <SelectInput alwaysOn fullWidth label="Site" source="site" choices={sitesChoices} />
    <SelectInput alwaysOn fullWidth label="DataType" source="dataType" choices={datatypesChoices} />
    <DateInput source="dateLastWatched_gte" label="dateLastWatched min" locales="fr-FR" alwaysOn />
    <DateInput source="dateLastWatched_lte" label="dateLastWatched max" locales="fr-FR" alwaysOn />
  </Filter>
);

export const WatcherbotCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <DateInput source="dateLastWatched" />
      <BooleanInput source="active" />
      <ReferenceInput
        label="Livre (isbn/titre)"
        source="book_byID"
        reference="books"
        alwaysOn
        fullWidth
      >
        <AutocompleteInput
          resettable
          optionText={choice => `${choice.isbn13} - ${choice.title}`}
          ptions={{
            fullWidth: true
          }}
        />
      </ReferenceInput>
      <ReferenceInput label="Utilisateur" source="user_byID" reference="users" fullWidth>
        <AutocompleteInput
          resettable
          optionText={choice => `${choice.email} - ${choice.name}`}
          ptions={{
            fullWidth: true
          }}
        />
      </ReferenceInput>
      <SelectInput fullWidth label="Site" source="site" choices={sitesChoices} />
      <SelectInput fullWidth label="DataType" source="dataType" choices={datatypesChoices} />
      <TextInput source="scraperbot" />
      <TextInput source="alertIf.method" />
      {/* todo: alertIf.selectInput */}
    </SimpleForm>
  </Create>
);

export const WatcherbotEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DateInput source="dateLastWatched" />
      <BooleanInput source="active" />
      <ReferenceField label="book" source="book" reference="books">
        <BookInfoField />
      </ReferenceField>
      <ReferenceField label="user" source="user" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <TextInput source="scraperbot" />
      <SelectInput fullWidth label="Site" source="site" choices={sitesChoices} />
      <SelectInput fullWidth label="DataType" source="dataType" choices={datatypesChoices} />
      <TextInput source="alertIf.method" />
      {/* <DateInput source="createdAt" /> */}
      {/* <DateInput source="updatedAt" /> */}
      {/* <TextInput source="id" /> */}
    </SimpleForm>
  </Edit>
);

const JsonDataField = ({ record = {} }) => <ReactJson src={record.dataSnapshot} theme="monokai" />;

export const WatcherbotShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <DateField source="dateLastWatched" locales="fr-FR" />
      <BooleanField source="active" />
      <TextField source="book" />
      <TextField source="user" />
      <TextField source="site" />
      <TextField source="scraperbot" />
      <TextField source="dataType" />
      <JsonDataField source="dataSnapshot" />
      <TextField source="alertIf.method" />
      <DateField source="createdAt" locales="fr-FR" />
      <DateField source="updatedAt" locales="fr-FR" />
      <TextField source="id" />
    </SimpleShowLayout>
  </Show>
);

const PostBulkActionButtons = ({ handleserverreponse, ...otherProps }) => (
  <Fragment>
    <BulkLaunchButton
      label="Reset Views"
      handleserverreponse={handleserverreponse}
      {...otherProps}
    />
    {/* Add the default bulk delete action */}
    {/* <BulkDeleteButton {...otherProps} /> */}
  </Fragment>
);

const rowStyle = (record, index) => ({
  backgroundColor: record.active ? '#e6ffe6' : 'white'
});

export class WatcherbotList extends Component {
  state = { watcherbotsserverresponse: {} };

  handleserverreponse = response => {
    this.setState({ watcherbotsserverresponse: response });
  };

  render() {
    const { watcherbotsserverresponse } = this.state;
    return (
      <Fragment>
        {watcherbotsserverresponse && <ServerResponse serverresponse={watcherbotsserverresponse} />}
        <List
          {...this.props}
          perPage={25}
          sort={{ field: 'dateLastWatched', order: 'DESC' }}
          filters={<Filters />}
          bulkActionButtons={
            <PostBulkActionButtons handleserverreponse={this.handleserverreponse} />
          }
        >
          <Datagrid rowStyle={rowStyle} rowClick="expand" expand={<WatcherbotShow />}>
            <BooleanField source="active" />
            <ReferenceField label="book" source="book" reference="books">
              <BookInfoField />
            </ReferenceField>
            <ReferenceField label="user" source="user" reference="users">
              <TextField source="email" />
            </ReferenceField>
            {/* <ReferenceField label="scraperbot" source="scraperbot" reference="scraperbots">
              <TextField source="id" />
            </ReferenceField> */}
            <SiteAvatarField />
            <TextField source="dataType" />
            <TextField source="alertIf.method" />
            <DateField source="dateLastWatched" locales="fr-FR" />
            {/* <TextField source="dataSnapshot" /> */}
            {/* <DateField source="createdAt" />
              <DateField source="updatedAt" />
              <TextField source="id" /> */}
            <EditButton />
          </Datagrid>
        </List>
      </Fragment>
    );
  }
}
