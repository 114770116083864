// in src/users.js
import React from 'react';
import {
  List,
  Edit,
  SimpleForm,
  Filter,
  ArrayField,
  SingleFieldList,
  ChipField,
  BooleanInput,
  DisabledInput,
  TextInput,
  DateInput,
  Datagrid,
  NumberField,
  TextField,
  BooleanField,
  FunctionField,
  EmailField,
  UrlField,
  DateField,
  ReferenceField,
  ShowGuesser,
  Show,
  SimpleShowLayout,
  Create,
  EditButton,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  NumberInput,
  LongTextInput,
  AutocompleteInput
} from 'react-admin';
import { BookInfoField } from '../bookInfoField';
import { SiteAvatarField } from '../siteAvatarField';
import { sitesChoices, datatypesChoices } from '../selectInputs';

const Filters = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Book (isbn/title)"
      source="book_byID"
      reference="books"
      alwaysOn
      fullWidth
    >
      <AutocompleteInput
        resettable
        optionText={choice => `${choice.isbn13} - ${choice.title}`}
        ptions={{
          fullWidth: true
        }}
      />
    </ReferenceInput>
    <SelectInput alwaysOn fullWidth label="Site" source="site" choices={sitesChoices} />
    <DateInput source="updatedAt_gte" label="updatedAt min" locales="fr-FR" alwaysOn />
    <DateInput source="updateAt_lte" label="updateAt max" locales="fr-FR" alwaysOn />
  </Filter>
);
// BookInfoField.defaultProps = { label: 'Name' };

export const ScrapedCommentCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Book (isbn/title)" source="book" reference="books" alwaysOn fullWidth>
        <AutocompleteInput
          resettable
          optionText={choice => `${choice.isbn13} - ${choice.title}`}
          options={{
            fullWidth: true
          }}
        />
      </ReferenceInput>
      <SelectInput label="Site" source="site" choices={sitesChoices} />
      <ArrayInput source="data">
        <SimpleFormIterator>
          <LongTextInput source="text" />
          <TextInput source="title" />
          <DateInput source="date" locales="fr-FR" />
          <NumberInput source="rating" />
          {/* <TextInput source="hash" /> */}
          <TextInput source="url" />
          <DateInput source="dateScraped" locales="fr-FR" />
        </SimpleFormIterator>
      </ArrayInput>
      {/* <DateInput source="updatedAt" /> */}
      {/* <TextInput source="id" /> */}
    </SimpleForm>
  </Create>
);

export const ScrapedCommentEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput label="Book (isbn/title)" source="book" reference="books" alwaysOn fullWidth>
        <AutocompleteInput
          resettable
          optionText={choice => `${choice.isbn13} - ${choice.title}`}
          options={{
            fullWidth: true
          }}
        />
      </ReferenceInput>
      <SelectInput label="Site" source="site" choices={sitesChoices} />
      <DisabledInput source="createdAt" />
      <DisabledInput source="updatedAt" />
      <ArrayInput source="data">
        <SimpleFormIterator>
          <DisabledInput source="_id" />
          <LongTextInput source="text" />
          <TextInput source="title" />
          <DateInput source="date" locales="fr-FR" />
          <NumberInput source="rating" />
          {/* <TextInput source="hash" /> */}
          <TextInput source="url" />
          <DateInput source="dateScraped" locales="fr-FR" />
        </SimpleFormIterator>
      </ArrayInput>

      {/* <TextInput source="id" /> */}
    </SimpleForm>
  </Edit>
);

export const ScrapedCommentShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <ArrayField source="data">
        <Datagrid>
          <TextField source="text" />
          <TextField source="title" />
          <DateField source="date" locales="fr-FR" />
          <NumberField source="rating" />
          {/* <StarRatingField /> */}
          <UrlField source="url" />
          <DateField source="dateScraped" locales="fr-FR" />
          <TextField source="hash" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export const ScrapedCommentList = props => (
  <List
    {...props}
    perPage={25}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    filters={<Filters />}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="expand" expand={<ScrapedCommentShow />}>
      <ReferenceField label="book" source="book" reference="books">
        <BookInfoField />
        {/* <TextField source="isbn" /> */}
      </ReferenceField>
      <SiteAvatarField />
      <DateField source="createdAt" locales="fr-FR" />
      <DateField source="updatedAt" locales="fr-FR" />
      <FunctionField
        label="latest dateScraped"
        locales="fr-FR"
        render={record => `${record.data[0].dateScraped}`}
      />
      {/* <CommentsCountField source="data" /> */}
      <FunctionField label="# comments" render={record => `${record.data.length}`} />
      {/* <ArrayField source="data"><SingleFieldList><ChipField source="_id" /></SingleFieldList></ArrayField> */}
      {/* <DateField source="updatedAt" /> */}
      {/* <TextField source="id" /> */}
      <EditButton />
    </Datagrid>
  </List>
);
