import React from 'react';
import {
  Container,
  Button,
  Loader,
  Table,
  Icon,
  Segment,
  Input,
  Message,
  Form
} from 'semantic-ui-react';
import * as moment from 'moment';
import { SCRAPPER_API, DOMAIN, SERVER } from '../../config';
import 'moment/locale/fr';

moment.locale('fr');

class TestArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      nbTestReports: 24,
      requesting: false
    };
  }

  componentDidMount() {
    this.fetchLatestReports(24);
  }

  fetchLatestReports = () => {
    if (this.state.requesting) {
      return;
    }
    const { nbTestReports } = this.state;
    const requestUrl = `${SCRAPPER_API}/getTestReports?limit=${nbTestReports}`;
    this.setState({ requesting: true, requestUrl });
    fetch(requestUrl)
      .then(res => res.json())
      .then(reports => {
        // console.dir(reports);
        this.setState({
          reports,
          requesting: false,
          error: ''
        });
      })
      .catch(err => {
        console.error(`error in fetchLatestReports : ${err}`);
        this.setState({
          requesting: false,
          error: err.message
        });
      });
  };

  makeRequest = () => {
    if (this.state.requesting) {
      return;
    }
    console.log('making request !');
    const requestUrl = `${SCRAPPER_API}/launchTests`;
    this.setState({ requesting: true, requestUrl });
    fetch(requestUrl)
      .then(res => {
        if (res.ok) return fetch(`${SCRAPPER_API}/getTestReports?limit=1`);

        console.error(res.statusText);
        throw Error(`[${res.status}] :${res.statusText}`);
      })
      .then(res => res.json())
      .then(report => {
        console.dir({ report });

        const reports = [...report, ...this.state.reports];
        this.setState({
          // filename: res.filename,
          reports,
          requesting: false,
          error: ''
        });
      })
      .catch(err => {
        console.error(`error in makeRequest() : ${err}`);
        this.setState({
          requesting: false,
          error: err.message
        });
      });
  };

  displayReportsTable = () => (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Fichier</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Durée (s)</Table.HeaderCell>
          <Table.HeaderCell># tests</Table.HeaderCell>
          <Table.HeaderCell>
            # failures <Icon name="attention" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            # pending <Icon name="pause" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            # success <Icon name="checkmark" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.state.reports.map(report => {
          const { htmlFile, stats } = report;
          const { start, duration, tests, passes, pending, failures } = stats;

          return (
            <Table.Row key={htmlFile}>
              <Table.Cell>
                <a target="_blank" href={`${SERVER}/testReports/${htmlFile}`}>{`${htmlFile}`}</a>
              </Table.Cell>
              <Table.Cell>{moment(start).format('lll')}</Table.Cell>
              <Table.Cell>{duration / 1000} s</Table.Cell>
              <Table.Cell>{tests}</Table.Cell>
              <Table.Cell error={failures > 0}>{failures}</Table.Cell>
              <Table.Cell>{pending}</Table.Cell>
              <Table.Cell>{passes}</Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );

  render() {
    return (
      <Container>
        <Segment inverted>
          <Form inverted onSubmit={this.fetchLatestReports}>
            <Form.Group>
              <Form.Field>
                <label>Nombre de rapports</label>
                <Input
                  type="number"
                  value={this.state.nbTestReports}
                  onChange={event => {
                    this.setState({ nbTestReports: +event.target.value });
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Message
              visible={Boolean(
                !this.state.requesting && this.state.error && this.state.error.length > 0
              )}
              error
              // header={`${this.state.requestUrl}`}
              content={`${this.state.error}`}
            />
            <Button loading={this.state.requesting} positive type="submit">
              Récupérer rapports
            </Button>
            <Button loading={this.state.requesting} onClick={() => this.makeRequest()}>
              Lancer tests
            </Button>
          </Form>
        </Segment>

        {this.state.reports.length > 0 ? this.displayReportsTable() : <Loader />}
      </Container>
    );
  }
}
// testReports/${this.state.filename}.html
// filename.html (link to other page) - date - duration - #tests - #passing - #pending - #failure

export default TestArea;
