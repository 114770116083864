import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import ReactJson from 'react-json-view';

export const ServerResponse = props => (
  <div style={{ margin: '1em' }}>
    <Typography variant="title">réponse serveur</Typography>
    <ReactJson src={props.serverresponse} theme="monokai" />
  </div>
);
