import React, { Component } from 'react';
import { Button } from 'react-admin';

import LaunchIcon from '@material-ui/icons/PlayArrow';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { SCRAPPER_API, FRONTEND_API_TOKEN } from '../../config';

export default class BulkLaunchButton extends React.Component {
  state = { loading: false, saveToDB: false, proxy: 'scraperbox'};

  handleClick = () => {
    const { basePath, startUndoable, resource, selectedIds } = this.props;
    console.log(`my props :`);

    this.setState({ loading: true });

    fetch(`${SCRAPPER_API}/scraperbots/launch`, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify({ scraperbotsIds: selectedIds, saveToDB: this.state.saveToDB, proxy: this.state.proxy}), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${FRONTEND_API_TOKEN}`
      }
    })
      .then(res => res.json())
      .then(response => {
        console.log('Success:', JSON.stringify(response));
        this.props.handleserverreponse(response);
      })
      .catch(error => {
        console.error('Error:', error);
        this.props.handleserverreponse(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
    console.log({ props: this.props });
  };

  handleClickRefresh = () => {
    const { basePath, startUndoable, resource, selectedIds } = this.props;
    console.log(`my props :`);

    this.setState({ loading: true });

    fetch(`${SCRAPPER_API}/scraperbots/refresh`, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify({ scraperbotsIds: selectedIds, saveToDB: this.state.saveToDB, proxy: this.state.proxy }), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${FRONTEND_API_TOKEN}`
      }
    })
      .then(res => res.json())
      .then(response => {
        console.log('Success:', JSON.stringify(response));
        this.props.handleserverreponse(response);
      })
      .catch(error => {
        console.error('Error:', error);
        this.props.handleserverreponse(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
    console.log({ props: this.props });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  toggleUseScraperbox = () => {
  	this.setState({
  		proxy: 'scraperbox'
  	})
  };

  toggleUseLuminati = () => {
  	this.setState({
  		proxy: 'luminati'
  	})
  };

  toggleUseNone = () => {
  	this.setState({
  		proxy: 'none'
  	})
  };

  toggleSaveToDB = () => {
    this.setState({
      saveToDB: !this.state.saveToDB
    });
  };

  render() {
    return (
      <FormGroup row={false}>
      	<div row={true}>
	        <Button label="Lancer ces scraperbots" onClick={this.handleClick}>
	          {/* <LaunchIcon /> */}
	          {this.state.loading ? <CircularProgress /> : <LaunchIcon />}
	        </Button>
	        <Button label="Rafraichir ces scraperbots" onClick={this.handleClickRefresh}>
	          {/* <LaunchIcon /> */}
	          {this.state.loading ? <CircularProgress /> : <LaunchIcon />}
	        </Button>
        </div>
        <div row={true}>
        <FormControlLabel
          control={
            <Switch checked={this.state.saveToDB} onChange={this.toggleSaveToDB} value="checkedA" />
          }
          label="saveToDB"
        />
        <FormControlLabel
          control={
            <Switch checked={this.state.proxy === 'scraperbox'} onChange={this.toggleUseScraperbox} value="checkedB" />
          }
          label="useScraperbox"
        />
        <FormControlLabel
          control={
            <Switch checked={this.state.proxy === 'luminati'} onChange={this.toggleUseLuminati} value="checkedC" />
          }
          label="useLuminati"
        />
        <FormControlLabel
          control={
            <Switch checked={this.state.proxy === 'none'} onChange={this.toggleUseNone} value="checkedD" />
          }
          label="useNone"
        />
        </div>
      </FormGroup>
    );
  }
}
