import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelIcon from '@material-ui/icons/Label';
import YoutubeSearchIcon from '@material-ui/icons/YoutubeSearchedFor';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CommentIcon from '@material-ui/icons/Comment';
import StarsIcon from '@material-ui/icons/Stars';
import StyleIcon from '@material-ui/icons/Style';
import VisibilityIcon from '@material-ui/icons/Visibility';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ListIcon from '@material-ui/icons/List';
import PersonIcon from '@material-ui/icons/Person';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import EditIcon from '@material-ui/icons/Edit';
import TimelineIcon from '@material-ui/icons/Timeline';
import VpnKey from '@material-ui/icons/VpnKey';
import Receipt from '@material-ui/icons/Receipt';
import TimerIcon from '@material-ui/icons/Timer';
import FileUpload from '@material-ui/icons/FileUpload';
import { withRouter } from 'react-router-dom';
import { translate, DashboardMenuItem, MenuItemLink, Responsive } from 'react-admin';
import { Icon, Sidebar, Segment, Message, Container } from 'semantic-ui-react';
import { DEBUG_MODE, DOMAIN, ENV, PAGES } from '../config';

import SubMenu from './SubMenu';

class Menu extends Component {
  state = {
    menuScraper: false,
    menuWatcher: false,
    menuAlert: false
    // menuWatcher: false,
    // menuAlerts: false
  };

  static propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object
  };

  handleToggle = menu => {
    this.setState(state => ({ [menu]: !state[menu] }));
  };

  render() {
    const { onMenuClick, open, logout, translate } = this.props;
    return (
      <div style={{ height: '100vh' }}>
        {' '}
        <DashboardMenuItem onClick={onMenuClick} />
        <MenuItemLink
          to="/books"
          primaryText="Livres"
          leftIcon={<LibraryBooksIcon />}
          onClick={onMenuClick}
        />
        <SubMenu
          handleToggle={() => this.handleToggle('menuScraper')}
          isOpen={this.state.menuScraper}
          sidebarIsOpen={open}
          name="Scraper"
          icon={<YoutubeSearchIcon />}
        >
          <MenuItemLink
            to="/scraperbots"
            primaryText="scraperbots"
            leftIcon={<ListIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to="/scraped-comments"
            primaryText="commentaires"
            leftIcon={<CommentIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to="/scraped-statistics"
            primaryText="statistiques"
            leftIcon={<StarsIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to="/scraped-formats"
            primaryText="formats"
            leftIcon={<StyleIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to="/frequency-profiles"
            primaryText="Frequency Profiles"
            leftIcon={<TimelineIcon/>}
            onClick={onMenuClick}
          />
          <MenuItemLink
          	to="/requests-statistics"
          	primaryText="stats requêtes"
          	onClick={onMenuClick}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => this.handleToggle('menuWatcher')}
          isOpen={this.state.menuWatcher}
          sidebarIsOpen={open}
          name="Watcher"
          icon={<VisibilityIcon />}
        >
          <MenuItemLink
            to="/watcherbots"
            primaryText="watcherbots"
            leftIcon={<ListIcon />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <MenuItemLink
          to="/alerts"
          primaryText="Alertes"
          leftIcon={<NotificationsIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to="/users"
          primaryText="Utilisateurs"
          leftIcon={<PersonIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to="/cron"
          primaryText="Cron"
          leftIcon={<AvTimerIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to="/dummy"
          primaryText="Dummy"
          leftIcon={<EditIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to="/apiTokens"
          primaryText="Tokens API"
          leftIcon={<VpnKey />}
          onClick={onMenuClick}
        />
        <SubMenu
          handleToggle={() => this.handleToggle('menuTraces')}
          isOpen={this.state.menuTraces}
          sidebarIsOpen={open}
          name="Traces"
          icon={<Receipt />}
        >
          <MenuItemLink
            to="/traces"
            primaryText="Traces"
            leftIcon={<Receipt />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to="/tracesChart"
            primaryText="Chart"
            leftIcon={<Receipt />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <MenuItemLink
          to="/tests"
          primaryText="Tests"
          leftIcon={<TimerIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
            to="/impAsin"
            primaryText="Import Asin"
            leftIcon={<FileUpload />}
            onClick={onMenuClick}
        />
        {/*
        <SubMenu
          handleToggle={() => this.handleToggle('menuCatalog')}
          isOpen={this.state.menuCatalog}
          sidebarIsOpen={open}
          name="pos.menu.catalog"
          icon={<products.icon />}
        >
          <MenuItemLink
            to="/products"
            primaryText={translate(`resources.products.name`, {
              smart_count: 2
            })}
            leftIcon={<products.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to="/categories"
            primaryText={translate(`resources.categories.name`, {
              smart_count: 2
            })}
            leftIcon={<categories.icon />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => this.handleToggle('menuCustomer')}
          isOpen={this.state.menuCustomer}
          sidebarIsOpen={open}
          name="pos.menu.customers"
          icon={<visitors.icon />}
        >
          <MenuItemLink
            to="/customers"
            primaryText={translate(`resources.customers.name`, {
              smart_count: 2
            })}
            leftIcon={<visitors.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to="/segments"
            primaryText={translate(`resources.segments.name`, {
              smart_count: 2
            })}
            leftIcon={<LabelIcon />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <MenuItemLink
          to="/reviews"
          primaryText={translate(`resources.reviews.name`, {
            smart_count: 2
          })}
          leftIcon={<reviews.icon />}
          onClick={onMenuClick}
        /> */}
        <Responsive
          xsmall={
            <MenuItemLink
              to="/configuration"
              primaryText="configuration"
              leftIcon={<SettingsIcon />}
              onClick={onMenuClick}
            />
          }
          medium={null}
        />
        <Responsive
          small={logout}
          medium={null} // Pass null to render nothing on larger devices
        />
        <Message
          info
          style={{
            position: 'fixed',
            bottom: '10px',
            width: '220px',
            marginLeft: '10px',
            paddingLeft: '18px',
            fontSize: '0.95rem',
            listStyleType: 'none'
          }}
        >
          <Message.List>
            <Message.Item>
              ENV:{'  '}
              <code>{ENV}</code>
            </Message.Item>
            <Message.Item>
              DOMAIN: <code>{DOMAIN}</code>
            </Message.Item>
            <Message.Item>
              DEBUG_MODE:{'  '}
              <Icon name={`${DEBUG_MODE ? 'checkmark' : 'remove'}`} />
            </Message.Item>
          </Message.List>
        </Message>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme
  //   locale: state.i18n.locale
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    {}
  ),
  translate
);

export default enhance(Menu);
