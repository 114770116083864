import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
  fetchUtils
} from 'react-admin';
import { stringify } from 'query-string';
import { SCRAPPER_API, FRONTEND_API_TOKEN } from './config';

const API_URL = SCRAPPER_API;

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
  console.log({ params, resource });
  switch (type) {
    case GET_LIST: {
      console.log(`inside get list !`);

      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const copyParamsFilter = { ...params.filter };
      console.log({ copyParamsFilter });
      const query = {
        sort: { [field]: order === 'DESC' ? -1 : 1 },
        range: [(page - 1) * perPage, page * perPage - 1],
        filter: params.filter
      };
      const sort = JSON.stringify({ [field]: order === 'DESC' ? -1 : 1 });
      const skip = (page - 1) * perPage;
      const limit = perPage;
      console.log({ filter: params.filter });
      const filterKeys = Object.keys(params.filter);
      for (const filter of filterKeys) {
        if (filter.includes('_byID')) {
          params.filter[filter.replace('_byID', '')] = params.filter[filter];
          delete params.filter[filter];
        } else if (filter.includes('_boolean')) {
          params.filter[filter.replace('_boolean', '')] = params.filter[filter];
          delete params.filter[filter];
        } else if (filter.includes('_gte')) {
          params.filter[filter.replace('_gte', '')] = { $gte: params.filter[filter] };
          delete params.filter[filter];
        } else if (filter.includes('_lte')) {
          params.filter[filter.replace('_lte', '')] = { $lte: params.filter[filter] };
          delete params.filter[filter];
        } else if (filter.includes('_exists')) {
          console.log(`checking if exists`);
          if (typeof params.filter[filter] === 'object') {
            const keys = Object.keys(params.filter[filter]);
            for (const key of keys) {
              params.filter[`${filter.replace('_exists', '')}.${key}`] = {
                $exists: params.filter[filter][key]
              };
            }
          } else {
            params.filter[filter.replace('_exists', '')] = { $exists: params.filter[filter] };
          }
          delete params.filter[filter];
        } else if (filter === 'q') {
          // full-text search, not implemented on our server-side
          // we create the query depending on the resource
          if (resource === 'books') {
            params.filter = {
              $or: [
                {
                  title: { $regex: `(${params.filter[filter]})`, $options: 'i' }
                },
                {
                  isbn13: { $regex: `(${params.filter[filter]})`, $options: 'i' }
                }
              ]
            };
            delete params.filter[filter];
          }
          if (resource === 'frequency-profiles') {
            params.filter = {
              $or: [
                {
                  name: { $regex: `(${params.filter[filter]})`, $options: 'i' }
                }
              ]
            };
            delete params.filter[filter];
          }
          if (resource === 'users') {
            params.filter = {
              $or: [
                {
                  email: { $regex: `(${params.filter[filter]})`, $options: 'i' }
                },
                {
                  name: { $regex: `(${params.filter[filter]})`, $options: 'i' }
                }
              ]
            };
            delete params.filter[filter];
          }
        } else if (typeof params.filter[filter] === 'object') {
          /*
                  websiteIdentifiers: {
                    asin: "77"
                  }  */
          const keys = Object.keys(params.filter[filter]);
          for (const key of keys) {
            params.filter[`${filter}.${key}`] = {
              $regex: `(${params.filter[filter][key]})`,
              $options: 'i'
            };
          }
          delete params.filter[filter];
        } else {
          params.filter[filter] = { $regex: `(${params.filter[filter]})`, $options: 'i' };
        }
      }
      console.log({ filter: params.filter });
      const filter = JSON.stringify(params.filter);
      let url = `${API_URL}/${resource}?sort=${sort}&skip=${skip}&limit=${limit}&query=${filter}`;
      //   if ( resource === 'scraped-comments' || resource === 'scraped-statistics' ){
      //       url+= `&populate=book`
      //   }
      if (resource === 'users') {
        url += `&populate=alertsNotNotified&populate=alerts`;
      }
      console.log({ url });
      return { url };
    }
    case GET_ONE: {
      let url = `${API_URL}/${resource}/${params.id}`;
      if (resource === 'users') {
        url += `?populate=alertsNotNotified&populate=alerts`;
      }
      return { url };
    }
    case GET_MANY: {
      // books?query={"_id":{"$in":["5c82ceffc5f7a808fcb6a7fd","5c82ceffc5f7a808fcb6a7fb"]}}
      console.log(`get many`);
      console.log({ params });
      const query = {
        filter: JSON.stringify({ id: params.ids })
      };
      const queryIds = {
        _id: {
          $in: params.ids
        }
      };
      console.log({ queryIds });
      const filter = JSON.stringify(queryIds);
      return { url: `${API_URL}/${resource}?query=${filter}` };
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      console.log({ params });
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({ ...params.filter, [params.target]: params.id })
      };
      return { url: `${API_URL}/${resource}?${stringify(query)}` };
    }
    case UPDATE:
      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: { method: 'PUT', body: JSON.stringify(params.data) }
      };
    case CREATE:
      return {
        url: `${API_URL}/${resource}`,
        options: { method: 'POST', body: JSON.stringify(params.data) }
      };
    case DELETE:
      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: { method: 'DELETE' }
      };
    case DELETE_MANY: {
      // const query = {
      //     filter: JSON.stringify({ id: params.ids }),
      // };
      const mongooseIds = [];
      for (const id of params.ids) {
        mongooseIds.push({
          _id: id
        });
      }
      return {
        url: `${API_URL}/${resource}`,
        options: { method: 'DELETE', body: JSON.stringify(mongooseIds) }
      };
    }
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
  console.log(`inside convertHTTPResponseToDataProvider`);
  const { headers, json } = response;
  console.log({ json: response.json });
  //   console.log({data: json.map(x => x)})
  console.log({ headers: headers.get('content-range') });
  console.log({ type });
  switch (type) {
    case GET_LIST:
      return {
        data: json.map(x => x),
        total: parseInt(
          headers
            .get('content-range')
            .split('/')
            .pop(),
          10
        )
      };
    case GET_ONE:
      return { data: json };
    case CREATE:
      return { data: { ...params.data, id: json.id } };
    default:
      return { data: json };
  }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  let { url, options } = convertDataProviderRequestToHTTP(type, resource, params);
  options = {
    ...options,
    headers: new Headers({
      Authorization: `Bearer ${FRONTEND_API_TOKEN}`
    })
  };
  // if (!options || typeof options === 'undefined') {
  //   options = {};
  // }
  // options.headers = {
  //   Authorization: `Bearer ${FRONTEND_API_TOKEN}`
  // };
  // options.headers.set('Authorization', `Bearer ${FRONTEND_API_TOKEN}`);
  return fetchJson(url, options).then(response =>
    convertHTTPResponseToDataProvider(response, type, resource, params)
  );
};
