import React, { Component } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

export default class Swagger extends Component {
  render() {
    // return <SwaggerUI url="https://petstore.swagger.io/v2/swagger.json" />;
    return <SwaggerUI url="localhost:8181/v1/" />;
  }
}
