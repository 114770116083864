import React, { Component } from 'react';
import * as moment from 'moment';
import ReactJson from 'react-json-view';
import 'moment/locale/fr';
import Button from '@material-ui/core/Button';
import LaunchIcon from '@material-ui/icons/PlayArrow';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import { withStyles } from '@material-ui/core/styles';
import { SCRAPPER_API, FRONTEND_API_TOKEN } from '../../config';

moment.locale('fr');

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  }
});

class Cron extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, cronServerResponse: {}, dryRun: true };
  }

  launchCron = () => {
    console.log(this.state);
    this.setState({
      loading: true
    });

    fetch(`${SCRAPPER_API}/cron/launch`, {
      method: 'POST', // or 'PUT'
      // body: JSON.stringify({ userIds: selectedIds, dryRun: this.state.dryRun }), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${FRONTEND_API_TOKEN}`
      }
    })
      .then(res => res.json())
      .then(response => {
        this.setState({ cronServerResponse: response });
      })
      .catch(error => {
        console.error('Error:', error);
        this.setState({ cronServerResponse: error });
      })
      .finally(() => {
        console.log(`inside finally`);
        this.setState({ loading: false });
      });
    /* request
      .post(`${SCRAPPER_API}/cron/launch`)
      .send({})
      .end((err, res) => {
        this.setState({ loading: false });
        if (err) {
          console.error(err);
          this.setState({ cronServerResponse: err });
        } else {
          console.log(`no errors`);
          console.log(res.body);
          this.setState({ cronServerResponse: res.body });
        }
      }); */
  };

  toggleDryRun = () => {
    this.setState({
      dryRun: !this.state.dryRun
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <FormGroup row={false}>
          <Button
            onClick={this.launchCron}
            disabled={this.state.loading}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Lancer le cron
            {this.state.loading ? (
              <CircularProgress className={classes.rightIcon} />
            ) : (
              <LaunchIcon className={classes.rightIcon} />
            )}
            {/* <AvTimerIcon className={classes.rightIcon} /> */}
          </Button>
          {/*   <FormControlLabel
            control={
              <Switch checked={this.state.dryRun} onChange={this.toggleDryRun} value="checkedA" />
            }
            label="dry run"
          /> */}
        </FormGroup>
        {/* <Checkbox label="dryRun" toggle onChange={this.toggleDryRun} checked={this.state.dryRun} /> */}
        <ReactJson src={this.state.cronServerResponse} theme="monokai" />
      </div>
    );
  }
}

export default withStyles(styles)(Cron);
