// in src/App.js
import React from 'react';
import { Admin, Resource, ListGuesser, EditGuesser, ShowGuesser } from 'react-admin';
import frenchMessages from 'ra-language-french';
import englishMessages from 'ra-language-english';
// import { PostList, PostEdit, PostCreate } from './posts';
import UserIcon from '@material-ui/icons/Group';
import BookIcon from '@material-ui/icons/Book';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Menu from './layout/Menu';
import Dashboard from './Dashboard';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import { UserList, UserEdit, UserCreate } from './components/users/index';
import { AlertList } from './components/alerts/index';
import { BookList, BookEdit, BookCreate } from './components/books';
import { ScraperbotList, ScraperbotEdit, ScraperbotCreate } from './components/scraperbots/index';
import { WatcherbotList, WatcherbotEdit, WatcherbotCreate } from './components/watcherbots/index';
import { TraceList } from './components/traces/index';
import {
  ScrapedCommentList,
  ScrapedCommentEdit,
  ScrapedCommentCreate
} from './components/scraperbots/comments';
import {
  ScrapedStatisticList,
  ScrapedStatisticEdit,
  ScrapedStatisticCreate
} from './components/scraperbots/statistics';
import {
  ScrapedFormatList
  // ScrapedStatisticEdit,
  // ScrapedStatisticCreate
} from './components/scraperbots/formats';
import {
  FrequencyProfilesList,
  FrequencyProfileCreate,
  FrequencyProfileEdit
} from './components/frequencyProfiles/index';
import customRoutes from './customRoutes';

const messages = {
  fr: frenchMessages,
  en: englishMessages
};

const i18nProvider = locale => messages[locale];
// const dataProvider = jsonServerProvider('http://jsonplaceholder.typicode.com');
// const dataProvider = jsonServerProvider('http://127.0.0.1:8181/api');
const App = () => (
  <Admin
    dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={dataProvider}
    customRoutes={customRoutes}
    locale="fr"
    i18nProvider={i18nProvider}
    menu={Menu}
  >
    {/* <Resource name="posts" list={PostList} /> */}
    {/* <Resource name="posts" list={PostList} edit={PostEdit} create={PostCreate}  icon={BookIcon} /> */}
    <Resource name="books" list={BookList} edit={BookEdit} create={BookCreate} icon={BookIcon} />

    <Resource
      name="scraperbots"
      list={ScraperbotList}
      edit={ScraperbotEdit}
      create={ScraperbotCreate}
      icon={UserIcon}
    />
    <Resource
      name="scraped-comments"
      list={ScrapedCommentList}
      edit={ScrapedCommentEdit}
      create={ScrapedCommentCreate}
      icon={UserIcon}
    />
    <Resource
      name="scraped-statistics"
      list={ScrapedStatisticList}
      edit={ScrapedStatisticEdit}
      create={ScrapedStatisticCreate}
      icon={UserIcon}
    />
    <Resource
      name="scraped-formats"
      list={ScrapedFormatList}
      // edit={ScrapedStatisticEdit}
      // create={ScrapedStatisticCreate}
      // icon={UserIcon}
    />
    <Resource
      name="watcherbots"
      list={WatcherbotList}
      edit={WatcherbotEdit}
      create={WatcherbotCreate}
      icon={UserIcon}
    />
    <Resource name="alerts" list={AlertList} edit={EditGuesser} icon={UserIcon} />
    <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} icon={UserIcon} />
    <Resource name="traces" list={TraceList} icon={ReceiptIcon} />
    <Resource
      name="frequency-profiles"
      list={FrequencyProfilesList}
      create={FrequencyProfileCreate}
      edit={FrequencyProfileEdit}
    />
  </Admin>
);

export default App;
